import { Box, IconButton, useTheme } from "@mui/material";
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import Clock from 'react-live-clock';
import moment from 'moment';
import 'moment/locale/es';

// Componente funcional Topbar
const Topbar = () => {
  // Establece la configuración regional de moment en español
  moment.locale('es');

  // Función para manejar el clic en el botón de salida
  function HandleClick(){
    // Borra las variables de sesión
    sessionStorage.clear();
  }

  // Devuelve un elemento JSX
  return (
    <Box display="grid"
    gridTemplateColumns="repeat(12, 1fr)"
    gridAutoRows="140px"
    gap="20px" >
      <Box component="div" sx={{ visibility: 'visible' }} display="flex" alignItems="center"
          justifyContent="center" gridColumn="span 11">
          {/* Componente reloj */}
        {<Clock date={''} format={'h:mm:ss A, LL'}  ticking={true} timezone={'America/Mexico_City'} />}
      </Box>
     
      {/* ICONOS */}
      <Box display="flex"
          alignItems="center"
          justifyContent="center"gridColumn="span 1">
          {/* Componente icono de salida con comportamiento para borrar las variables de sesión y redirigir al usuario */}
        <IconButton href="/" onClick={HandleClick}>
          <ExitToAppOutlinedIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Topbar;
