// Importa componentes de Material UI para la interfaz de usuario
import { Box, useTheme } from "@mui/material";
// Importa el componente Header personalizado
import Header from "../../components/Header";
// Importa tokens desde el archivo de tema local
import { tokens } from "../../theme";

// Importa componentes de react-leaflet para mostrar un mapa
import { MapContainer, TileLayer, Marker, Tooltip} from 'react-leaflet';
// Importa estilos de Leaflet
import 'leaflet/dist/leaflet.css';
// Importa Leaflet
import L from 'leaflet';

// Importa useNavigate de react-router-dom para navegar entre páginas
import { useNavigate } from 'react-router-dom';

// Importa React
import React, { useEffect, useState } from "react";


import { urls } from "../../data/urls";

const estaciones = [
  //allen
  { id: 1, nombre: 'Vallarta', latitud: 20.679053, longitud: -103.351851, estado: 'encendida', alias:'davvallarta' },
  { id: 2, nombre: 'Barcena', latitud: 20.692724, longitud: -103.329490, estado: 'encendida', alias: 'dbarcena' },
  { id: 3, nombre: 'De Banderas', latitud: 20.736145, longitud: -103.321865, estado: 'encendida', alias: 'dbdebanderas' },
  { id: 4, nombre: 'Calle 22', latitud: 20.674844, longitud: -103.344680, estado: 'encendida', alias: 'dcalle22' },
  { id: 5, nombre: 'Calle 26', latitud: 20.657059, longitud: -103.315639, estado: 'encendida', alias: 'dcalle26' },
  { id: 6, nombre: 'DC Juarez', latitud: 20.672662,  longitud: -103.339787, estado: 'encendida', alias: 'dcjuarez' },
  { id: 7, nombre: 'Construccion', latitud: 20.686057, longitud: -103.408095, estado: 'encendida', alias: 'dconstruccion' },
  { id: 8, nombre: 'Díaz de Leon', latitud: 20.689053, longitud: -103.341851, estado: 'encendida', alias: 'ddiazdeleon'},
  { id: 9, nombre: 'España', latitud: 20.702724, longitud: -103.329490, estado: 'encendida', alias: 'despana'},
  { id: 10, nombre: 'Gante', latitud: 20.726145, longitud: -103.321865, estado: 'encendida', alias: 'dgante'},
  { id: 11, nombre: 'Gonzalez Gallo', latitud: 20.674844, longitud: -103.354680, estado: 'encendida', alias: 'dgonzalezgallo' },
  { id: 12, nombre: 'Igualdad', latitud: 20.657059, longitud: -103.345639, estado: 'encendida', alias: 'digualdad'},
  { id: 13, nombre: 'Mina', latitud: 20.662662,  longitud: -103.379787, estado: 'encendida', alias: 'djmina' },
  { id: 14, nombre: 'Rio de la barca', latitud: 20.686057, longitud: -103.408095, estado: 'encendida', alias: 'driolabarca' },
  { id: 15, nombre: 'Siderurgia', latitud: 20.697059, longitud: -103.335639, estado: 'encendida', alias: 'dsiderurgia' },
  { id: 16, nombre: 'Nevada', latitud: 20.662662,  longitud: -103.309787, estado: 'encendida', alias: 'dsnevada' },
  { id: 17, nombre: 'Violeta', latitud: 20.676057, longitud: -103.418095, estado: 'encendida', alias: 'dvioleta' },
  //siemens
  //{ id: 18, nombre: 'Constituyentes', latitud: 20.689053, longitud: -103.351851, estado: 'desactivada', alias: 'Constituyentes'},
  { id: 19, nombre: 'Montevideo', latitud: 20.692724, longitud: -103.329490, estado: 'encendida', alias:'Montevideo' },
  { id: 20, nombre: 'Americas', latitud: 20.716145, longitud: -103.321865, estado: 'encendida', alias: 'Americas' },
  //{ id: 21, nombre: 'Juan de la barrera', latitud: 20.674844, longitud: -103.354680, estado: 'desactivada', alias:'Juan de la barrera' },
  //{ id: 22, nombre: 'Malecón', latitud: 20.657059, longitud: -103.335639, estado: 'desactivada', alias: 'Malecón'},
  { id: 23, nombre: 'Normalistas', latitud: 20.662662,  longitud: -103.339787, estado: 'encendida', alias:'Normalistas' },
  //{ id: 24, nombre: 'Colón', latitud: 20.676057, longitud: -103.408095, estado: 'desactivada' },
  
  
  // Agrega más estaciones según tus necesidades
];

const iconEncendida = new L.Icon({
  iconUrl: '../../assets/icono-encendida.png',
  iconSize: [30, 30],
});

const iconApagada = new L.Icon({
  iconUrl: '../../assets/icono-apagada.png',
  iconSize: [30, 30],
});

const iconDesactivada= new L.Icon({
  iconUrl: '../../assets/icono-desactivada.png',
  iconSize: [30, 30],
});


// Define el componente Inicio
const Inicio = () => {
  // Usa useTheme para acceder al tema actual
  const theme = useTheme();
  // Usa tokens para acceder a los colores del tema
  const colors = tokens(theme.palette.mode);
  // Usa useNavigate para navegar entre páginas
  const navigate = useNavigate();

  const [marcadores, setMarcadores] = useState([]);

  const stations = async () => {
    await fetch(urls.estaciones, {
          
      method: 'GET',
      headers: {
        accesstoken: sessionStorage.getItem('JWT')
      },
    })
    
    .then((response) => response.json())
    .then(response => {

      //console.log(response);
      setMarcadores(response.map(estacion => ({
        id: parseInt(estacion.id_estacion),
        nombre: estacion.nombre,
        latitud: estacion.latitud,
        longitud: estacion.longitud,
        estado: 'encendida',
        alias: estacion.alias
      })));
      //console.log(resultado);
      
    })

    .catch(err => {
      //console.log("error" + err);
    })
  }

  useEffect(() => {
    stations();
  }, []);

  // Define una función para manejar el clic en un marcador del mapa
  function HandleClick(pipo, pipo1){
    navigate('/estacion',{state:{id:1,name:pipo, alias: pipo1}});
  }

  // Define un componente MapComponent para mostrar un mapa con marcadores para cada estación
  function MapComponent() {
    return (
      <MapContainer center={[20.6925, -103.3199]} zoom={14} scrollWheelZoom={false} doubleClickZoom ={false}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

        {/* Muestra un marcador en el mapa para cada estación */}
        {marcadores.map((estacion) => {
          let markerIcon;

          // Selecciona el ícono del marcador según el estado de la estación
          switch (estacion.estado) {
            case 'encendida':
              markerIcon = iconEncendida;
              break;
            case 'apagada':
              markerIcon = iconApagada;
              break;
            case 'desactivada':
              markerIcon = iconDesactivada;
              break;
            default:
              markerIcon = iconDesactivada;
              break;
          }

          return (
            <Marker
              key={estacion.id}
              position={[estacion.latitud, estacion.longitud]}
              icon={markerIcon}
              eventHandlers={{click: () => HandleClick(estacion.nombre, estacion.alias)}}
            >
              {/* Muestra un Tooltip con el nombre de la estación */}
              <Tooltip direction="top" offset={[0, -10]} opacity={1} permanent>
                {estacion.nombre}
              </Tooltip>
            </Marker>
          );
        })}
      </MapContainer>
    );
  }

  return (
    <>
      <Box m="20px">
        {/* Renderiza el componente Header con el título especificado */}
        <Header title="Estaciones"/>
        <Box
          height="75vh"
          border={`1px solid ${colors.grey[100]}`}
          borderRadius="4px"
        >
          {/* Renderiza el componente MapComponent */}
          <MapComponent/>
        </Box>
      </Box>
    </>
  );
};

export default Inicio;
