import { Box, Typography, useTheme, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
//import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import React, { useEffect, useState } from "react";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import { useNavigate } from 'react-router-dom';

//Componentes para el texto del modal
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
//

import { urls } from "../../data/urls";

function Modal({ message, onClose }) {
  return (
    
    <Dialog open={true} onClose={onClose}>
      <DialogContent>
        <p>{message}</p>
      </DialogContent>
    </Dialog>
  );
}

const ConsultarTipo = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const permisos = JSON.parse(sessionStorage.getItem('permisos'));
  
  const [station, setStation] = useState([]);
  
  const [message, setMessage] = useState('');
  
  const [showModal, setShowModal] = useState(false);

  const deleteStation = (id) => {
    
    fetch(urls.configEstacionId(id), {
      
      method: 'DELETE',
      headers: {
        accesstoken: sessionStorage.getItem('JWT')
      },
    })
    
    .then((response) => response.json())
    .then(response => {
      setMessage(JSON.stringify(response.message));
      setShowModal(true);
    })
    .catch(err => {
      console.log("fetch error" + err);
      setMessage("Error de comunicación: " + err);
      setShowModal(true);
    })  
}


  //es el metodo que permite obtener la lista de usuarios de la base de datos 
  const checkstations = () => {
    
      fetch(urls.configTipoEquipo, {
        
        method: 'GET',
        headers: {
          accesstoken: sessionStorage.getItem('JWT')
        },
      })
      
      .then((response) => response.json())
      .then(response => {
        response.sort((a, b) => a.id_tipo_equipo- b.id_tipo_equipo);
        setStation(response);
        console.log(response); 
      })
      .catch(err => {
        console.log("fetch error" + err);
      })
      
      
  }


  //Columnas del data grid
  const columns = [
    
    { field: "id_tipo_equipo", 
    headerName: "ID",
    type: "number", },
    
    {
      field: "nombre",
      headerName: "Nombre",
      flex: 1,
    },
    {
      field: "modificar",
      headerName: "Modificar estacion",
      renderCell: ( {row: { id_tipo_equipo }} ) => (
        <IconButton onClick={() => { navigate('/ajustes_estacion/actualizar_tipos_equipos',{state:{id:id_tipo_equipo}}) }}>
          <ModeEditOutlineOutlinedIcon />
        </IconButton>
      ),
    },
    {
      field: "borrar",
      headerName: "Borrar estacion",
      renderCell: ({row: { id_tipo_equipo }}) => (
        <IconButton onClick={() => { deleteStation(id_tipo_equipo) }}>
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      ),
    },
  ];

  //El useEffect le dice a react que debe realizar algo despues de renderizar  
  useEffect(() => {
    checkstations();
  }, [] );

  return (
    <Box m="20px">
      <Header title="CRUD DE TIPOS DE EQUIPO" subtitle="Maneja los tipos de equipo." />
      <Box
        m="40px 0 0 0"
        height="60vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        {
          // Creacion del data grid en react, usa las columnas declaras con anterioridad y los datos de los usuarios como filas
        }
        <DataGrid getRowId={(row) => row.id_tipo_equipo} rows={station} columns={columns} />
        {showModal && <Modal 
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        message={message} onClose={() => setShowModal(false)} />}
      </Box>
    </Box>
  );
};

export default ConsultarTipo;
