// Se importan los componentes Box y Button de la biblioteca Material UI
import { Box, Button } from "@mui/material";

// Se importa el componente Header desde un archivo local
import Header from "../../components/Header";

// Se importan los estilos CSS para la biblioteca leaflet
import 'leaflet/dist/leaflet.css';

// Se importan varios iconos desde la biblioteca Material UI
import CloudOutlinedIcon from '@mui/icons-material/CloudOutlined';
import Co2OutlinedIcon from '@mui/icons-material/Co2Outlined';
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import SensorsOutlinedIcon from '@mui/icons-material/SensorsOutlined';

// Se importa el hook useNavigate desde la biblioteca react-router-dom
import { useNavigate } from 'react-router-dom';

// Se importa la función styled desde la biblioteca Material UI para crear componentes con estilos personalizados
import { styled } from '@mui/material/styles';

// Se crea un componente MyButton personalizado utilizando la función styled y el componente Button de Material UI
const MyButton = styled(Button)({
  backgroundColor:  '#1F2A40',
  color: '#e0e0e0',
  fontSize: '14px',
  fontWeight: 'bold',
  gridColumn: 'span 2',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  marginTop: '25px',
  padding: '0 30px',
  '@media (min-width:768px)': {
    fontSize: '16px',
    padding: '10px 40px',
    margin: '10px',
  },
});

// Se crea un componente ButtonContainer personalizado utilizando la función styled y el componente Box de Material UI
const ButtonContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
  '@media (max-width:768px)': {
    flexDirection: 'column',
    alignItems: 'center',
  },
});

// Se crean varios componentes de iconos personalizados utilizando la función styled y los componentes de iconos de Material UI
const MyIcon1 = styled(Co2OutlinedIcon)({
  color: '#70d8bd',
  fontSize: '26px',
});
const MyIcon2 = styled(CloudOutlinedIcon)({
  color: '#70d8bd',
  fontSize: '26px',
});
const MyIcon3 = styled(SensorsOutlinedIcon)({
  color: '#70d8bd',
  fontSize: '26px',
});
const MyIcon4 = styled(ColorLensOutlinedIcon)({
  color: '#70d8bd',
  fontSize: '26px',
});
const MyIcon5 = styled(AssessmentOutlinedIcon)({
  color: '#70d8bd',
  fontSize: '26px',
});


// Se define un componente de React llamado AlarmasMenu
const AlarmasMenu = () => {
  // Se utiliza el hook useNavigate para obtener una función para navegar entre rutas
  const navigate = useNavigate();

  // Se devuelve la estructura de elementos del componente
  return (
    <>
      {/* Primer contenedor Box con un componente Header dentro */}
      <Box m="20px" display="flex" justifyContent="space-between" alignItems="center">
        <Header title="ALARMAS" subtitle="Consulta las alarmas de los dispositivos" />
      </Box>

      {/* Primer contenedor ButtonContainer con varios botones MyButton dentro */}
      <ButtonContainer>
        {/* Botón para navegar a la ruta /ajustes_variables/gas */}
        <MyButton
          onClick={() => {
            navigate('/alarmas/gas');
          }}
        >
          <MyIcon1 />
          Alarmas Gas
        </MyButton>

        {/* Botón para navegar a la ruta /ajustes_variables/meteorologico 
        <MyButton
          onClick={() => {
            navigate('/alarmas/meteorologico');
          }}
        >
          <MyIcon2 />
          Alarmas Meteorologico
        </MyButton>
        */}
        
        {/* Botón para navegar a la ruta /ajustes_variables/sensor */}
        <MyButton
          onClick={() => {
            navigate('/alarmas/missing');
          }}
        >
          <MyIcon3 />
          Alarmas Missing
        </MyButton>

        {/* Botón para navegar a la ruta /ajustes_variables/colorimetria */}
        <MyButton
          onClick={() => {
            navigate('/alarmas/disabled');
          }}
        >
          <MyIcon4 />
          Alarmas disabled
        </MyButton>

        {/* Botón para navegar a la ruta /ajustes_variables/unidad */}
        <MyButton
          onClick={() => {
            navigate('/alarmas/sistema_monitoreo');
          }}
        >
          <MyIcon5 />
          Alarmas Sistema Monitoreo
        </MyButton>

        {/* Botón para navegar a la ruta /ajustes_variables/unidad */}
        <MyButton
          onClick={() => {
            navigate('/alarmas/sistema');
          }}
        >
          <MyIcon5 />
          Alarmas Sistema
        </MyButton>

        {/* Botón para navegar a la ruta /ajustes_variables/unidad */}
        <MyButton
          onClick={() => {
            navigate('/alarmas/falla');
          }}
        >
          <MyIcon5 />
          Alarmas Falla
        </MyButton>

        {/* Botón para navegar a la ruta /ajustes_variables/unidad */}
        <MyButton
          onClick={() => {
            navigate('/alarmas/gabinete_otros');
          }}
        >
          <MyIcon5 />
          Alarmas Gabinete Otros
        </MyButton>

        {/* Botón para navegar a la ruta /ajustes_variables/unidad */}
        <MyButton
          onClick={() => {
            navigate('/alarmas/gabinete_puerta');
          }}
        >
          <MyIcon5 />
          Alarmas Gabinete Puerta
        </MyButton>

        {/* ... agregar más botones según sea necesario */}
      </ButtonContainer>
    </>
  );
};

// Se exporta el componente AlarmasMenu como el valor predeterminado del módulo
export default AlarmasMenu;
