// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html,\nbody,\n#root,\n.app,\n.protected,\n.content {\n  width: 100%;\n  font-family: \"Source Sans Pro\", sans-serif;\n}\n\n.app {\n  display: flex;\n  position: relative;\n}\n\n.protected {\n  display: flex;\n  position: relative;\n}\n\n::-webkit-scrollbar {\n  width: 10px;\n}\n\n\n\n.leaflet-container{\n  width: 100%;\n  height: 100%;\n}\n\n.form-container {\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));\n  grid-gap: 20px;\n}\n\n.form-wrapper {\n  flex: 1 1;\n  min-width: 300px;\n  margin: 10px;\n}\n.TableContainer {\n  max-height: 100%;\n  overflow: auto;\n}\n.sidebar, .pro-sidebar-collapsed {\n  height: 100%;\n}\n\n\n\n\n\n\n\n", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;;;;;;EAME,WAAW;EACX,0CAA0C;AAC5C;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;;;AAIA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,2DAA2D;EAC3D,cAAc;AAChB;;AAEA;EACE,SAAO;EACP,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,YAAY;AACd","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap\");\n\nhtml,\nbody,\n#root,\n.app,\n.protected,\n.content {\n  width: 100%;\n  font-family: \"Source Sans Pro\", sans-serif;\n}\n\n.app {\n  display: flex;\n  position: relative;\n}\n\n.protected {\n  display: flex;\n  position: relative;\n}\n\n::-webkit-scrollbar {\n  width: 10px;\n}\n\n\n\n.leaflet-container{\n  width: 100%;\n  height: 100%;\n}\n\n.form-container {\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));\n  grid-gap: 20px;\n}\n\n.form-wrapper {\n  flex: 1;\n  min-width: 300px;\n  margin: 10px;\n}\n.TableContainer {\n  max-height: 100%;\n  overflow: auto;\n}\n.sidebar, .pro-sidebar-collapsed {\n  height: 100%;\n}\n\n\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
