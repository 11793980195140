const baseUrl = 'https://siapa.ciateq.net.mx';

export const urls = {
  alarmasGas: `${baseUrl}/backend/alarmas/gas`,
  alarmasReconocida: `${baseUrl}/backend/alarmas/reconocida`,
  alarmasMissing: `${baseUrl}/backend/alarmas/missing`,
  alarmasDisabled: `${baseUrl}/backend/alarmas/disabled`,
  alarmasSistemaMonitoreo: `${baseUrl}/backend/alarmas/sistema-monitoreo`,
  alarmasSistema: `${baseUrl}/backend/alarmas/sistema`,
  alarmasFalla: `${baseUrl}/backend/alarmas/falla`,
  alarmasGabineteOtros: `${baseUrl}/backend/alarmas/gabinete-otros`,
  alarmasGabinetePuerta: `${baseUrl}/backend/alarmas/gabinete-puerta`,
  alarmasMeteorologico: `${baseUrl}/backend/alarmas/meteorologico`,
  auth: `${baseUrl}/backend/auth`,
  configDatosEstaciones: `${baseUrl}/backend/config/datos-estaciones`,
  configGas: `${baseUrl}/backend/config/gas`,
  configUnidadMedida: `${baseUrl}/backend/config/unidad-medida`,
  configColorimetria: `${baseUrl}/backend/config/colorimetria`,
  configTipoSensor: `${baseUrl}/backend/config/tipo-sensor`,
  configMeteorologico: `${baseUrl}/backend/config/meteorologico`,
  configEstacionId: id => `${baseUrl}/backend/config/estacion/${id}`,
  configTipoEquipo: `${baseUrl}/backend/config/tipo-equipo`,
  configTipoEquipoId: id => `${baseUrl}/backend/config/tipo-equipo/${id}`,
  configGasId: gas => `${baseUrl}/backend/config/gas/${gas}`,
  configUnidadMedidaId: id => `${baseUrl}/backend/config/unidad-medida/${id}`,
  configColorimetriaId: id => `${baseUrl}/backend/config/colorimetria/${id}`,
  configTipoSensorId: id => `${baseUrl}/backend/config/tipo-sensor/${id}`,
  configMeteorologicoId: id => `${baseUrl}/backend/config/meteorologico/${id}`,
  estaciones: `${baseUrl}/backend/config/estaciones`,
  estacionesAlias: alias => `${baseUrl}/backend/estaciones/${alias}`,
  usersGetAll: `${baseUrl}/backend/users`,
  usersGetId: id => `${baseUrl}/backend/users/${id}`,
  usersPostAll: `${baseUrl}/backend/users`,
  usersPutId: id => `${baseUrl}/backend/users/${id}`,
  usersDeleteId: id => `${baseUrl}/backend/users/${id}`,
  verify: `${baseUrl}/backend/verify`
};


