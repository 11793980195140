import { useState, useEffect  } from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./scenes/dashboard";
import Alarmas from "./scenes/alarmas";
import AlarmasGas from "./scenes/alarmas/AlarmasGas";
import AlarmasSistema from "./scenes/alarmas/AlarmasSistema";
import AlarmasFalla from "./scenes/alarmas/AlarmasFalla";
import AlarmasSistemaMonitoreo from "./scenes/alarmas/AlarmasSistemaMonitoreo";
import AlarmasGabineteOtros from "./scenes/alarmas/AlarmasGabineteOtros";
import AlarmasGabinetePuerta from "./scenes/alarmas/AlarmasGabinetePuerta";
import AlarmasMissing from "./scenes/alarmas/AlarmasMissing";
import AlarmasDisabled from "./scenes/alarmas/AlarmasDisabled";
import AlarmasMeteorologico from "./scenes/alarmas/AlarmasMeteorologico";
import ConsultarUsuarios from "./scenes/usuarios/ConsultarUsuarios";
import ActualizarUsuarios from "./scenes/usuarios/ActualizarUsuarios";
import CrearUsuarios from "./scenes/usuarios/CrearUsuarios";
import Usuarios from "./scenes/usuarios";

import ProtectedRoutes from "./utils/ProtectedRoutes";
import PrivateRoute from "./utils/PrivateRoute";
import Inicio from "./scenes/inicio";
import Login from "./scenes/login";
import Ajustesvariables from "./scenes/ajustes_variables";
import AjusteSistema from "./scenes/configsistema";
import GasAjustes from "./scenes/ajustes_variables/Gas";
import UnidadAjustes from "./scenes/ajustes_variables/Unidad";
import SensorAjustes from "./scenes/ajustes_variables/Sensor";
import MeteorologicoAjustes from "./scenes/ajustes_variables/Meteorologico";
import ColorimetriaAjustes from "./scenes/ajustes_variables/Colorimetria";
import CrearColorimetria from "./scenes/ajustes_variables/CrearColorimetria";
import CrearMeteorologico from "./scenes/ajustes_variables/CrearMeteorologico";
import CrearUnidad from "./scenes/ajustes_variables/CrearUnidad";
import CrearSensor from "./scenes/ajustes_variables/CrearSensor";
import CrearGas from "./scenes/ajustes_variables/CrearGas";
import AjustesEstacion from "./scenes/ajustes_estacion";
import ConsultarEstacion from "./scenes/ajustes_estacion/Consultar";
import CrearEstacion from "./scenes/ajustes_estacion/Crear";
import ActualizarEstacion from "./scenes/ajustes_estacion/Actualizar";
import ConsultarTipo from "./scenes/ajustes_estacion/ConsultarTipo";
import CrearTipo from "./scenes/ajustes_estacion/CrearTipo";
import ActualizarTipo from "./scenes/ajustes_estacion/ActualizarTipo";
import Logs from "./scenes/log";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Auth from "./auth/auth";

function PageNotFound() {
  return (
    <div>
      <h2>404 Page not found</h2>
    </div>
  );
}


function App() {
  const [theme, colorMode] = useMode();


  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">



          <main className="content">


            <Routes>
            <Route element={<Auth/>} >
              <Route path="/" element={<Login />} />
            </Route>
            
            <Route path="/inicio" element=
            {<ProtectedRoutes>
                  <PrivateRoute allowedRoles={[1,2,3,4,5,6]}>
            <Inicio />
          </PrivateRoute>
              </ProtectedRoutes>
            } />

            <Route path="/usuarios" element=
            {<ProtectedRoutes>
                <PrivateRoute allowedRoles={[1,2]}>
                  <Usuarios />
                </PrivateRoute>
              </ProtectedRoutes>
            } />

            <Route path="/usuarios/consultar" element=
            {<ProtectedRoutes>
                <PrivateRoute allowedRoles={[1,2]}>
                  <ConsultarUsuarios />
                </PrivateRoute>
              </ProtectedRoutes>
            } />

            <Route path="/usuarios/actualizar" element=
              {<ProtectedRoutes>
                <PrivateRoute allowedRoles={[1,2]}>
                  <ActualizarUsuarios />
                </PrivateRoute>
              </ProtectedRoutes>
              } />
            
            <Route path="/usuarios/crear" element=
            {<ProtectedRoutes>
              <PrivateRoute allowedRoles={[1,2]}>
                <CrearUsuarios />
              </PrivateRoute>
                
              </ProtectedRoutes>
            } />

            

            <Route path="/alarmas" element=
            {<ProtectedRoutes>
               <PrivateRoute allowedRoles={[1,2,3,4,5]}>
                  <Alarmas />
               </PrivateRoute>
               
              </ProtectedRoutes>
            } />

            <Route path="/alarmas/gas" element=
            {<ProtectedRoutes>
               <PrivateRoute allowedRoles={[1,2,3,4,5]}>
                  <AlarmasGas />
               </PrivateRoute>
               
              </ProtectedRoutes>
            } />

            <Route path="/alarmas/sistema" element=
            {<ProtectedRoutes>
               <PrivateRoute allowedRoles={[1,2,3,4,5]}>
                  <AlarmasSistema />
               </PrivateRoute>
               
              </ProtectedRoutes>
            } />

            <Route path="/alarmas/falla" element=
            {<ProtectedRoutes>
               <PrivateRoute allowedRoles={[1,2,3,4,5]}>
                  <AlarmasFalla />
               </PrivateRoute>
               
              </ProtectedRoutes>
            } />

            <Route path="/alarmas/gabinete_puerta" element=
            {<ProtectedRoutes>
               <PrivateRoute allowedRoles={[1,2,3,4,5]}>
                  <AlarmasGabinetePuerta />
               </PrivateRoute>
               
              </ProtectedRoutes>
            } />

            <Route path="/alarmas/gabinete_otros" element=
            {<ProtectedRoutes>
               <PrivateRoute allowedRoles={[1,2,3,4,5]}>
                  <AlarmasGabineteOtros />
               </PrivateRoute>
               
              </ProtectedRoutes>
            } />

            <Route path="/alarmas/sistema_monitoreo" element=
            {<ProtectedRoutes>
               <PrivateRoute allowedRoles={[1,2,3,4,5]}>
                  <AlarmasSistemaMonitoreo />
               </PrivateRoute>
               
              </ProtectedRoutes>
            } />

            <Route path="/alarmas/disabled" element=
            {<ProtectedRoutes>
               <PrivateRoute allowedRoles={[1,2,3,4,5]}>
                  <AlarmasDisabled />
               </PrivateRoute>
               
              </ProtectedRoutes>
            } />

            <Route path="/alarmas/missing" element=
            {<ProtectedRoutes>
               <PrivateRoute allowedRoles={[1,2,3,4,5]}>
                  <AlarmasMissing />
               </PrivateRoute>
               
              </ProtectedRoutes>
            } />

            <Route path="/alarmas/meteorologico" element=
            {<ProtectedRoutes>
               <PrivateRoute allowedRoles={[1,2,3,4,5]}>
                  <AlarmasMeteorologico/>
               </PrivateRoute>
               
              </ProtectedRoutes>
            } />

            <Route path="/estacion" element=
            {<ProtectedRoutes>
              <PrivateRoute allowedRoles={[1,2,3,4,5,6]}>
              <Dashboard />
              </PrivateRoute>
                
              </ProtectedRoutes>
            } />

            

            <Route path="/ajustes_variables" element=
            {<ProtectedRoutes>
              <PrivateRoute allowedRoles={[1,2,3]}>
                <Ajustesvariables />
              </PrivateRoute>
                
              </ProtectedRoutes>
            } />

            <Route path="/ajustes_variables/crear_colorimetria" element=
            {<ProtectedRoutes>
              <PrivateRoute allowedRoles={[1,2,3]}>
                <CrearColorimetria />
              </PrivateRoute>
                
              </ProtectedRoutes>
            } />

            <Route path="/ajustes_variables/crear_meteorologico" element=
            {<ProtectedRoutes>
              <PrivateRoute allowedRoles={[1,2,3]}>
                <CrearMeteorologico />
              </PrivateRoute>
            </ProtectedRoutes>
            } />

            <Route path="/ajustes_variables/crear_gas" element=
            {<ProtectedRoutes>
              <PrivateRoute allowedRoles={[1,2,3]}>
                <CrearGas />
              </PrivateRoute>
              </ProtectedRoutes>
            } />

            <Route path="/ajustes_variables/crear_unidad" element=
            {<ProtectedRoutes>
              <PrivateRoute allowedRoles={[1,2,3]}>
                <CrearUnidad />
              </PrivateRoute>
            </ProtectedRoutes>
            } />

            <Route path="/ajustes_variables/crear_sensor" element=
            {<ProtectedRoutes>
              <PrivateRoute allowedRoles={[1,2,3]}>
                <CrearSensor />
              </PrivateRoute>
            </ProtectedRoutes>
            } />

            <Route path="/ajustes_variables/gas" element=
            {<ProtectedRoutes>
              <PrivateRoute allowedRoles={[1,2,3]}>
                <GasAjustes />
              </PrivateRoute>
                
              </ProtectedRoutes>
            } />

            <Route path="/ajustes_variables/colorimetria" element=
            {<ProtectedRoutes>
                <PrivateRoute allowedRoles={[1,2,3]}>
                  <ColorimetriaAjustes />
                </PrivateRoute>
              </ProtectedRoutes>
            } />

            <Route path="/ajustes_variables/sensor" element=
            {<ProtectedRoutes>
                <PrivateRoute allowedRoles={[1,2,3]}>
                  <SensorAjustes />
                </PrivateRoute>
              </ProtectedRoutes>
            } />

            <Route path="/ajustes_variables/unidad" element=
            {<ProtectedRoutes>
                <PrivateRoute allowedRoles={[1,2,3]}>
                  <UnidadAjustes />
                </PrivateRoute>
              </ProtectedRoutes>
            } />

            <Route path="/ajustes_variables/meteorologico" element=
            {<ProtectedRoutes>
              <PrivateRoute allowedRoles={[1,2,3]}> 
                <MeteorologicoAjustes />
              </PrivateRoute>
                
              </ProtectedRoutes>
            } />

            <Route path="/ajustes_estacion" element=
            {<ProtectedRoutes>
                <PrivateRoute allowedRoles={[1]}> 
                  <AjustesEstacion />
                </PrivateRoute>
              </ProtectedRoutes>
            } />

            <Route path="/ajustes_estacion/consultar_estaciones" element=
            {<ProtectedRoutes>
                <PrivateRoute allowedRoles={[1]}> 
                  <ConsultarEstacion />
                </PrivateRoute>
              </ProtectedRoutes>
            } />

            <Route path="/ajustes_estacion/crear_estaciones" element=
            {<ProtectedRoutes>
                <PrivateRoute allowedRoles={[1]}> 
                  <CrearEstacion />
                </PrivateRoute>
              </ProtectedRoutes>
            } />

            <Route path="/ajustes_estacion/actualizar_estaciones" element=
            {<ProtectedRoutes>
                <PrivateRoute allowedRoles={[1]}> 
                  <ActualizarEstacion />
                </PrivateRoute>
              </ProtectedRoutes>
            } />

            <Route path="/ajustes_estacion/consultar_tipos_equipos" element=
            {<ProtectedRoutes>
                <PrivateRoute allowedRoles={[1]}> 
                  <ConsultarTipo />
                </PrivateRoute>
              </ProtectedRoutes>
            } />
            {/*
            <Route path="/ajustes_estacion/crear_tipos_equipos" element=
            {<ProtectedRoutes>
                <PrivateRoute allowedRoles={[1]}> 
                  <CrearTipo />
                </PrivateRoute>
              </ProtectedRoutes>
            } />

            <Route path="/ajustes_estacion/actualizar_tipos_equipos" element=
            {<ProtectedRoutes>
                <PrivateRoute allowedRoles={[1]}> 
                  <ActualizarTipo />
                </PrivateRoute>
              </ProtectedRoutes>
            } />

            
            <Route path="/logs" element=
            {<ProtectedRoutes>
                <PrivateRoute allowedRoles={[1]}> 
                  <Logs />
                </PrivateRoute>
              </ProtectedRoutes>
            } />
            <Route path="/sistema" element=
            {<ProtectedRoutes>
                <PrivateRoute allowedRoles={[1]}> 
                  <AjusteSistema />
                </PrivateRoute>
              </ProtectedRoutes>
            } />
          */}

            {/* 
            

            

            

            <Route path="/ajustes/estado" element=
            {<ProtectedRoutes>
                <PrivateRoute allowedRoles={[1,2,3]}>
                  <EstadoAjustes />
                </PrivateRoute>
              </ProtectedRoutes>
            } />

            <Route path="/ajustes/modo" element=
            {<ProtectedRoutes>
                <PrivateRoute allowedRoles={[1,2,3]}>
                  <ModoAjustes />
                </PrivateRoute>
              </ProtectedRoutes>
            } />
  

            */}

            <Route path="*" element={<PageNotFound />} />

              
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;