import { Box, useTheme} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";

import Header from "../../components/Header";
import React, { useEffect, useState } from "react";




const Log = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  
  const [log, setLog] = useState([]);



  //es el metodo que permite obtener la lista de usuarios de la base de datos 
  const checkLogs= () => {
    
      fetch('https://siapa.ciateq.net.mx/backend/api/logs', {
        
        method: 'GET',
        headers: {
          accesstoken: sessionStorage.getItem('JWT')
        },
      })
      
      .then((response) => response.json())
      .then(response => {
        setLog(response);
        //console.log(data); 
      })
      .catch(err => {
        console.log("fetch error" + err);
      })
      
      
  }


  //Columnas del data grid
  const columns = [
    
    { field: "id_log", headerName: "ID" },
    {
      field: "descripcion",
      headerName: "Descripcion",
      flex: 1,
    },
    {
      field: "fecha",
      headerName: "Fecha",
      flex: 1,
    },
    {
      field: "id_objetos_log",
      headerName: "id_objetos_log",
      flex: 1,
    },
    {
      field: "meta",
      headerName: "Meta",
      flex: 1,
    },
    {
      field: "tipo",
      headerName: "Tipo",
      flex: 1,
    },
  ];

  //El useEffect le dice a react que debe realizar algo despues de renderizar  
  useEffect(() => {
    checkLogs();
  }, [] );

  return (
    <Box m="20px">
      <Header title="LOGS" subtitle="Registro de acciones" />
      <Box
        m="40px 0 0 0"
        height="60vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        {
          // Creacion del data grid en react, usa las columnas declaras con anterioridad y los datos de los usuarios como filas
        }
        <DataGrid getRowId={(row) => row.id_log} rows={log} columns={columns} />

      </Box>
    </Box>
  );
};

export default Log;
