import { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

function TablaAlarmas() {
  const [alarms, setAlarms] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetch('https://siapa.ciateq.net.mx/backend/api/monitoreo-gas')
        .then(response => response.json())
        .then(response => {
          
          setAlarms(response);
        })
    }, (parseInt(sessionStorage.getItem('tiempo')))*1000);
  
    return () => clearInterval(interval);
  }, []);

      return (
        <div className="table-container">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Fecha</TableCell>

                {/*<TableCell>Alarm Type</TableCell>*/}
                 {/*<TableCell>Alarm ID</TableCell>*/}
                 
                <TableCell>Estacion</TableCell>
                <TableCell>Gas</TableCell>
                {/*<TableCell>Gas Monitoring ID</TableCell>*/}
                <TableCell>Usuario</TableCell>
                <TableCell>Reconocida</TableCell>
                <TableCell>Valor</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {alarms?.map((alarm,index) => (
                <TableRow key={index} sx={{ backgroundColor: alarm.es_alarma_alta ? '#F26263'
                : '#F2A962',}}>
                  <TableCell>{alarm.fecha_disparo}</TableCell>
                   {/*<TableCell>{alarm.es_alarma_alta ? 'ALTA' : 'MEDIA'}</TableCell>*/}
                   {/*<TableCell>{alarm.id_alarma_monitoreo_gas}</TableCell>*/}
                  <TableCell>{alarm.estacion}</TableCell>
                  <TableCell>{alarm.gas}</TableCell>
                   {/*<TableCell>{alarm.id_monitoreo_gas}</TableCell>*/}
                  <TableCell>{alarm.id_usuario}</TableCell>
                  <TableCell>{alarm.reconocida ? 'Si' : 'No'}</TableCell>
                  <TableCell>{alarm.valor}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      );
    };

export default TablaAlarmas;
