import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";

import Header from "../../components/Header";

import LineChart from "../../components/LineChart";

import 'leaflet/dist/leaflet.css';

import {useLocation} from 'react-router-dom';

import React, { useEffect, useState } from "react";

import Switch from '@mui/material/Switch';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import TableContainer from '@mui/material/TableContainer';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';


import Paper from '@mui/material/Paper';

import { useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';

import { urls } from "../../data/urls";

function createData2(name, stat) {
  return { name, stat};
}

function convertirDireccionViento(direccion) {
  // Convertir el número a un rango válido (0-359)
  const direccionNormalizada = (direccion % 360 + 360) % 360;

  // Definir los segmentos de dirección y sus correspondientes cadenas de texto
  const segmentos = [
    { min: 0, max: 45, texto: 'Norte' },
    { min: 45, max: 135, texto: 'Este' },
    { min: 135, max: 225, texto: 'Sur' },
    { min: 225, max: 315, texto: 'Oeste' },
    { min: 315, max: 360, texto: 'Norte' },
  ];

  // Encontrar el segmento correspondiente a la dirección
  const segmento = segmentos.find(
    ({ min, max }) => direccionNormalizada >= min && direccionNormalizada < max
  );

  // Devolver la cadena de texto correspondiente o 'N' si no se encuentra el segmento
  return segmento ? segmento.texto : 'N';
}

const ChartContainer = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(12, 1fr)',
  gridAutoRows: '140px',
  gap: '20px',
  margin: '20px',
  '@media (max-width:768px)': {
    gridTemplateColumns: '1fr',
  },
  
});


const Dashboard = () => {
  const maxPoints = 2;
  const [dataCO, setDataCO] = useState([
    {
      id: 'CO',
      data: [],
    },
  ]);
  const [dataH2S, setDataH2S] = useState([
    {
      id: 'H2S',
      data: [],
    },
  ]);
  const [dataVOCS, setDataVOCS] = useState([
    {
      id: 'VOCS',
      data: [],
    },
  ]);
  const [dataO2, setDataO2] = useState([
    {
      id: 'O2',
      data: [],
    },
  ]);
  const [dataLEL, setDataLEL] = useState([
    {
      id: 'LEL',
      data: [],
    },
  ]);
  const permisos = JSON.parse(sessionStorage.getItem('permisos'));
  const [plc, setPlc] = useState(); 

  const [temp, setTemp] = useState(); 
  const [lel, setLel] = useState(); 
  const [lel_int, setLel_int] = useState(); 
  const [h2s, setH2s] = useState(); 
  const [co, setCo] = useState(); 
  const [o2, setO2] = useState(); 
  const [voc, setVoc] = useState(); 

  //vars_estado_sistema
  ///SIEMENS / PROTOTIPO
  const [sistema_estacion_vandalizada, setSistema_estacion_vandalizada] = useState();
  ///ALLEN BRADLEY
  const [sistema_mod_mantenimiento_1, setSistema_mod_mantenimiento_1] = useState();
  const [sistema_mod_mantenimiento_2, setSistema_mod_mantenimiento_2] = useState();
  const [sistema_estacion_fuera, setSistema_estacion_fuera] = useState();

  //vars_estado_disabled
  ///SIEMENS
  const [disabled_int_col, setDisabled_int_col] = useState();
  const [disabled_lel, setDisabled_lel] = useState();
  const [disabled_o2, setDisabled_o2] = useState();
  const [disabled_co, setDisabled_co] = useState();
  const [disabled_h2s, setDisabled_h2s] = useState();
  ///SIEMENS / PROTOTIPO
  const [disabled_chemgard, setDisabled_chemgard] = useState();
  ///ALLEN BRADLEY
  const [disabled_sensor_1, setDisabled_sensor_1] = useState();
  const [disabled_sensor_2, setDisabled_sensor_2] = useState();
  const [disabled_sensor_3, setDisabled_sensor_3] = useState();
  const [disabled_sensor_4, setDisabled_sensor_4] = useState();

  //vars_estado_missing
  ///SIEMENS
  const [missing_int_col, setMissing_int_col] = useState();
  const [missing_lel, setMissing_lel] = useState();
  const [missing_o2, setMissing_o2] = useState();
  const [missing_co, setMissing_co] = useState();
  const [missing_h2s, setMissing_h2s] = useState();
  

  //vars_estado_falla
  ///SIEMENS
  const [falla_bomba_xe, setFalla_bomba_xe] = useState();
  const [falla_transmisor_1, setFalla_transmisor_1] = useState();
  const [falla_transmisor_2, setFalla_transmisor_2] = useState();
  ///PROTOTIPO
  const [falla_sensor_voc, setFalla_sensor_voc] = useState();
  const [falla_sensor_h2s, setFalla_sensor_h2s] = useState();
  const [falla_sensor_lel, setFalla_sensor_lel] = useState();
  const [falla_sensor_co, setFalla_sensor_co] = useState();
  const [falla_sensor_o2, setFalla_sensor_o2] = useState();
  const [falla_sensor_ammonia, setFalla_sensor_ammonia] = useState();
  ///SIEMENS / PROTOTIPO
  const [falla_chemgard, setFalla_chemgard] = useState();
  const [falla_sensor_alcantarillado_capacitivo, setFalla_sensor_alcantarillado_capacitivo] = useState();
  const [falla_enable_bomba, setFalla_enable_bomba] = useState();
  ///ALLEN BRADLEY
  const [falla_x3, setFalla_x3] = useState();

  //vars_estado_entrada
  ///SIEMENS / PROTOTIPO
  const [entrada_puente_chem, setEntrada_puente_chem] = useState();
  const [entrada_puente_xe, setEntrada_puente_xe] = useState();
  const [entrada_on_off_chemgard, setEntrada_on_off_chemgard] = useState();
  const [entrada_reset_bombas, setEntrada_reset_bombas] = useState();
  const [entrada_enable_electrovalvula, setEntrada_enable_electrovalvula] = useState();
  const [entrada_enable_ventiladores, setEntrada_enable_ventiladores] = useState();
  ///ALLEN BRADLEY
  const [entrada_flujo_bomba, setEntrada_flujo_bomba] = useState();


  //vars_estado_gabinete_puerta_abierta
  ///SIEMENS / PROTOTIPO
  const [gabinete_puerta_abierta_ups, setGabinete_puerta_abierta_ups] = useState();
  const [gabinete_puerta_abierta_control, setGabinete_puerta_abierta_control] = useState();
  const [gabinete_puerta_abierta_sensores, setGabinete_puerta_abierta_sensores] = useState();
  const [gabinete_puerta_abierta_calibracion, setGabinete_puerta_abierta_calibracion] = useState();
  const [gabinete_puerta_abierta_filtros, setGabinete_puerta_abierta_filtros] = useState();
  ///ALLEN BRADLEY
  const [gabinete_puerta_abierta_puerta, setGabinete_puerta_abierta_puerta] = useState();

  //vars_estado_gabinete_encendidas
  ///SIEMENS / PROTOTIPO
  const [gabinete_encendidas_bombas, setGabinete_encendidas_bombas] = useState();
  ///ALLEN BRADLEY
  const [gabinete_encendidas_boton_bomba, setGabinete_encendidas_boton_bomba] = useState();
  const [gabinete_encendidas_on_off_bomba_manual, setGabinete_encendidas_on_off_bomba_manual] = useState();
  
  //vars_estado_gabinete_otros
  ///SIEMENS / PROTOTIPO
  const [gabinete_otros_mantenimiento, setGabinete_otros_mantenimiento] = useState();
  const [gabinete_otros_ventilador, setGabinete_otros_ventilador] = useState();
  const [gabinete_otros_capacitivo, setGabinete_otros_capacitivo] = useState();
  const [gabinete_otros_bomba_obstruida, setGabinete_otros_bomba_obstruida] = useState();
  const [gabinete_otros_alarma_puertas_abiertas, setGabinete_otros_alarma_puertas_abiertas] = useState();
  const [gabinete_otros_nivel_agua, setGabinete_otros_nivel_agua] = useState();
  const [gabinete_otros_electrovalvulas, setGabinete_otros_electrovalvulas] = useState();
  ///ALLEN BRADLEY
  const [gabinete_otros_alarma_tableros_123, setGabinete_otros_alarma_tableros_123] = useState();
  const [gabinete_otros_alerta_media_h2s, setGabinete_otros_alerta_media_h2s] = useState();
  const [gabinete_otros_alerta_media_o2, setGabinete_otros_alerta_media_o2] = useState();
  const [gabinete_otros_alerta_media_lel, setGabinete_otros_alerta_media_lel] = useState();
  const [gabinete_otros_alerta_media_co, setGabinete_otros_alerta_media_co] = useState();
  const [gabinete_otros_alerta_media_vocs, setGabinete_otros_alerta_media_vocs] = useState();
  const [gabinete_otros_alerta_alta_vocs, setGabinete_otros_alerta_alta_vocs] = useState();
  const [gabinete_otros_alerta_alta_h2s, setGabinete_otros_alerta_alta_h2s] = useState();
  const [gabinete_otros_alerta_alta_o2, setGabinete_otros_alerta_alta_o2] = useState();
  const [gabinete_otros_modbus_device_status, setGabinete_otros_modbus_device_status] = useState();
  const [gabinete_otros_manual_auto, setGabinete_otros_manual_auto] = useState();
  const [gabinete_otros_manual_auto_indicador, setGabinete_otros_manual_auto_indicador] = useState();



  const [presion, setPresion] = useState(); 
  const [velocidad, setVelocidad] = useState(); 
  const [humedad, setHumedad] = useState(); 
  const [precipitacion, setPrecipitacion] = useState(); 
  const [direccion, setDireccion] = useState(); 
  const [radiacion, setRadiacion] = useState();
  

  const [cfe, setCfe] = useState(); 
  const [flujo, setFlujo] = useState(); 
  const [nivel, setNivel] = useState(); 


  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const location = useLocation();

  let rows1 = [
    //{ dispositivo: 'PUERTA', estado: false },
    //{ dispositivo: 'BOMBA', estado: true },
    //{ dispositivo: 'MODO', estado: false },
    //{ dispositivo: 'FLUJO', estado: flujo },
    //{ dispositivo: 'NIVEL', estado: nivel },
    //{ dispositivo: 'NIVEL (LS)', estado: true },
    //{ dispositivo: 'COMMS', estado: false },
    //{ dispositivo: 'FALLA X3', estado: true },
    //{ dispositivo: 'CALIBRACION', estado: false },
    //{ dispositivo: 'CHEMGARD', estado: true }
  ];
  
  if (plc === 'ALLEN BRADLEY') {
    rows1.unshift({ dispositivo: 'manual_auto_indicador', estado: gabinete_otros_manual_auto_indicador });
    rows1.push({ dispositivo: 'sistema_mod_mantenimiento_1', estado: sistema_mod_mantenimiento_1 });
    rows1.push({ dispositivo: 'sistema_mod_mantenimiento_2', estado: sistema_mod_mantenimiento_2 });
    rows1.push({ dispositivo: 'sistema_estacion_fuera', estado: sistema_estacion_fuera });

    rows1.push({ dispositivo: 'disabled_sensor_1', estado: disabled_sensor_1 });
    rows1.push({ dispositivo: 'disabled_sensor_2', estado: disabled_sensor_2 });
    rows1.push({ dispositivo: 'disabled_sensor_3', estado: disabled_sensor_3 });
    rows1.push({ dispositivo: 'disabled_sensor_4', estado: disabled_sensor_4 });

    
    rows1.push({ dispositivo: 'falla_x3', estado: falla_x3 });
    rows1.push({ dispositivo: 'entrada_flujo_bomba', estado: entrada_flujo_bomba });

    rows1.push({ dispositivo: 'gabinete_puerta_abierta_puerta', estado: gabinete_puerta_abierta_puerta });
    
    rows1.push({ dispositivo: 'gabinete_encendidas_boton_bomba', estado: gabinete_encendidas_boton_bomba });
    rows1.push({ dispositivo: 'gabinete_encendidas_on_off_bomba_manual', estado: gabinete_encendidas_on_off_bomba_manual })
    
    rows1.push({ dispositivo: 'gabinete_otros_alarma_tableros_123', estado: gabinete_otros_alarma_tableros_123 });
    rows1.push({ dispositivo: 'gabinete_otros_alerta_media_h2s', estado: gabinete_otros_alerta_media_h2s });
    rows1.push({ dispositivo: 'gabinete_otros_alerta_media_o2', estado:gabinete_otros_alerta_media_o2});
    rows1.push({dispositivo:'gabinete_otros_alerta_media_lel',estado:gabinete_otros_alerta_media_lel});
    rows1.push({dispositivo:'gabinete_otros_alerta_media_co',estado:gabinete_otros_alerta_media_co});
    rows1.push({dispositivo:'gabinete_otros_alerta_media_vocs',estado:gabinete_otros_alerta_media_vocs});
    rows1.push({dispositivo:'gabinete_otros_alerta_alta_vocs',estado:gabinete_otros_alerta_alta_vocs});
    rows1.push({dispositivo:'gabinete_otros_alerta_alta_h2s',estado:gabinete_otros_alerta_alta_h2s});
    rows1.push({dispositivo:'gabinete_otros_alerta_alta_o2',estado:gabinete_otros_alerta_alta_o2});
    rows1.push({dispositivo:'gabinete_otros_modbus_device_status',estado:gabinete_otros_modbus_device_status});
    rows1.push({dispositivo:'gabinete_otros_manual_auto',estado:gabinete_otros_manual_auto});
    rows1.push({dispositivo:'gabinete_otros_manual_auto_indicador',estado:gabinete_otros_manual_auto_indicador});
} else {
    rows1.unshift({ dispositivo: 'sistema_estacion_vandalizada', estado:sistema_estacion_vandalizada});

    rows1.push({ dispositivo:'disabled_chemgard',estado:disabled_chemgard});

    rows1.push({dispositivo:'falla_chemgard',estado:falla_chemgard});
    rows1.push({dispositivo:'falla_sensor_alcantarillado_capacitivo',estado:falla_sensor_alcantarillado_capacitivo});
    rows1.push({dispositivo:'falla_enable_bomba',estado:falla_enable_bomba});

    rows1.push({dispositivo:'entrada_puente_chem',estado:entrada_puente_chem});
    rows1.push({dispositivo:'entrada_puente_xe',estado:entrada_puente_xe});
    rows1.push({dispositivo:'entrada_on_off_chemgard',estado:entrada_on_off_chemgard});
    rows1.push({dispositivo:'entrada_reset_bombas',estado:entrada_reset_bombas});
    rows1.push({dispositivo:'entrada_enable_electrovalvula',estado:entrada_enable_electrovalvula});
    rows1.push({dispositivo:'entrada_enable_ventiladores',estado:entrada_enable_ventiladores});

    rows1.push({dispositivo:'gabinete_puerta_abierta_ups',estado:gabinete_puerta_abierta_ups});
    rows1.push({dispositivo:'gabinete_puerta_abierta_control',estado:gabinete_puerta_abierta_control});
    rows1.push({dispositivo:'gabinete_puerta_abierta_sensores',estado:gabinete_puerta_abierta_sensores});
    rows1.push({dispositivo:'gabinete_puerta_abierta_calibracion',estado:gabinete_puerta_abierta_calibracion});
    rows1.push({dispositivo:'gabinete_puerta_abierta_filtros',estado:gabinete_puerta_abierta_filtros});

    rows1.push({dispositivo:'gabinete_encendidas_bombas',estado:gabinete_encendidas_bombas});

    rows1.push({dispositivo:'gabinete_otros_mantenimiento',estado:gabinete_otros_mantenimiento});
    rows1.push({dispositivo:'gabinete_otros_ventilador',estado:gabinete_otros_ventilador});
    rows1.push({dispositivo:'gabinete_otros_capacitivo',estado:gabinete_otros_capacitivo});
    rows1.push({dispositivo:'gabinete_otros_bomba_obstruida',estado:gabinete_otros_bomba_obstruida});
    rows1.push({dispositivo:'gabinete_otros_alarma_puertas_abiertas',estado:gabinete_otros_alarma_puertas_abiertas});
    rows1.push({dispositivo:'gabinete_otros_nivel_agua',estado:gabinete_otros_nivel_agua});
    rows1.push({dispositivo:'gabinete_otros_electrovalvulas',estado:gabinete_otros_electrovalvulas});

    if(plc === 'SIEMENS'){
      rows1.push({dispositivo:'falla_bomba_xe',estado:falla_bomba_xe});
      rows1.push({dispositivo:'falla_transmisor_1',estado:falla_transmisor_1});
      rows1.push({dispositivo:'falla_transmisor_2',estado:falla_transmisor_2});

      rows1.push({dispositivo:'missing_co',estado:missing_co});
      rows1.push({dispositivo:'missing_h2s',estado:missing_h2s});
      rows1.push({dispositivo:'missing_int_col',estado:missing_int_col});
      rows1.push({dispositivo:'missing_o2',estado:missing_o2});
      rows1.push({dispositivo:'missing_lel',estado:missing_lel});

      rows1.push({dispositivo:'disabled_lel',estado:disabled_lel});
      rows1.push({dispositivo:'disabled_int_col',estado:disabled_int_col});
      rows1.push({dispositivo:'disabled_o2',estado:disabled_o2});
      rows1.push({dispositivo:'disabled_co',estado:disabled_co});
      rows1.push({dispositivo:'disabled_h2s',estado:disabled_h2s});
    }
    else{
      rows1.push({ dispositivo: 'falla_sensor_voc', estado: falla_sensor_voc });
      rows1.push({ dispositivo: 'falla_sensor_h2s', estado: falla_sensor_h2s });
      rows1.push({ dispositivo: 'falla_sensor_lel', estado: falla_sensor_lel });
      rows1.push({ dispositivo: 'falla_sensor_co', estado: falla_sensor_co });
      rows1.push({ dispositivo: 'falla_sensor_o2', estado: falla_sensor_o2 });
      rows1.push({ dispositivo: 'falla_sensor_ammonia', estado: falla_sensor_ammonia });
    }
}

  let rows = [
    createData2('LEL', lel < -90 ? "NA" : (lel === undefined ? "UN" : (lel === null ? "NULL" : Math.floor(lel * 100) / 100 + "%"))),
    createData2('H2S', h2s < -90 ? "NA" : (h2s === undefined ? "UN" : (h2s === null ? "NULL" : Math.floor(h2s * 100) / 100 + "%"))),
    createData2('C0', co < -90 ? "NA" : (co === undefined ? "UN" : (co === null ? "NULL" : Math.floor(co * 100) / 100 + "PPM"))),
    createData2('02', o2 < -90 ? "NA" : (o2 === undefined ? "UN" : (o2 === null ? "NULL" : Math.floor(o2 * 100) / 100 + "PPM"))),
    createData2('VOC', voc < -90 ? "NA" : (voc === undefined ? "UN" : (voc === null ? "NULL" : Math.floor(voc * 100) / 100 + "PPM"))),
  ];
  
  if (plc === 'SIEMENS') {
    rows.splice(1, 0, createData2('LEL INT', lel_int < -90 ? "NA" : (lel_int === undefined ? "UN" : (lel_int === null ? "NULL" : Math.floor(lel_int * 100) / 100 + "%"))));
  }

  /*
  if (plc === 'ALLEN BRADLEY'){
    const newrows = [
    createData2('Presion Atmosferica', presion),
    createData2('Velocidad Viento', velocidad),
    createData2('Humedad Relativa', humedad),
    createData2('Precipitacion Pluvial', precipitacion),
    createData2('Dirección Viento', convertirDireccionViento(direccion)),
    createData2('Radiacion Solar', radiacion),
  ];
  }
  */
    
    // Define una función para obtener los datos de la estación
  const checkDatos = () => {
    // Realiza una solicitud GET a la URL especificada en urls.estacionesAlias
    fetch(urls.estacionesAlias(location.state.alias), {
      method: 'GET',
      headers: {
        // Incluye el JWT almacenado en sessionStorage como un encabezado de solicitud
        accesstoken: sessionStorage.getItem('JWT')
      },
    })
    .then((response) => response.json())
    .then(response => {
      //console.log(response);
      // Actualiza el estado con los datos recibidos en la respuesta
      setTemp(response.jsonData.monitoreo.jsonData.sistema.temperatura);
      setLel(response.jsonData.monitoreo.jsonData.gas.lel);
      setO2(response.jsonData.monitoreo.jsonData.gas.o2);
      setH2s(response.jsonData.monitoreo.jsonData.gas.h2s);
      setCo(response.jsonData.monitoreo.jsonData.gas.co);
      setVoc(response.jsonData.monitoreo.jsonData.gas.voc);

      setPlc(response.plc);
      
      // Si el PLC es ALLEN BRADLEY
      if (response.plc === 'ALLEN BRADLEY') {
        setCfe(response.jsonData.monitoreo.jsonData.sistema.cfe);
        setFlujo(response.jsonData.monitoreo.jsonData.sistema.flujo);
        setNivel(response.jsonData.monitoreo.jsonData.sistema.nivel_agua);

        setSistema_mod_mantenimiento_1(response.jsonData.estados.jsonData.sistema.mod_mantenimiento_1);
        setSistema_mod_mantenimiento_2(response.jsonData.estados.jsonData.sistema.mod_mantenimiento_2);
        setSistema_estacion_fuera(response.jsonData.estados.jsonData.sistema.estacion_fuera);

        setDisabled_sensor_1(response.jsonData.estados.jsonData.disabled.disable_sensor_1);
        setDisabled_sensor_2(response.jsonData.estados.jsonData.disabled.disable_sensor_2);
        setDisabled_sensor_3(response.jsonData.estados.jsonData.disabled.disable_sensor_3);
        setDisabled_sensor_4(response.jsonData.estados.jsonData.disabled.disable_sensor_4);

        
        setFalla_x3(response.jsonData.estados.jsonData.falla.disable_sensor_2);
        setEntrada_flujo_bomba(response.jsonData.estados.jsonData.entrada.flujo_bomba);

        setGabinete_puerta_abierta_puerta(response.jsonData.estados.jsonData.gabinete.puerta_abierta.puerta);
        
        setGabinete_encendidas_boton_bomba(response.jsonData.estados.jsonData.gabinete.encendidas.boton_bomba);
        setGabinete_encendidas_on_off_bomba_manual(response.jsonData.estados.jsonData.gabinete.encendidas.on_off_bomba_manual)
        
        setGabinete_otros_alarma_tableros_123(response.jsonData.estados.jsonData.gabinete.otros.alarma_tableros_123);
        setGabinete_otros_alerta_media_h2s(response.jsonData.estados.jsonData.gabinete.otros.alerta_media_h2s);
        setGabinete_otros_alerta_media_o2(response.jsonData.estados.jsonData.gabinete.otros.alerta_media_o2);
        setGabinete_otros_alerta_media_lel(response.jsonData.estados.jsonData.gabinete.otros.alerta_media_lel);
        setGabinete_otros_alerta_media_co(response.jsonData.estados.jsonData.gabinete.otros.alerta_media_co);
        setGabinete_otros_alerta_media_vocs(response.jsonData.estados.jsonData.gabinete.otros.alerta_media_vocs);
        setGabinete_otros_alerta_alta_vocs(response.jsonData.estados.jsonData.gabinete.otros.alerta_alta_vocs);
        setGabinete_otros_alerta_alta_h2s(response.jsonData.estados.jsonData.gabinete.otros.alerta_alta_h2s);
        setGabinete_otros_alerta_alta_o2(response.jsonData.estados.jsonData.gabinete.otros.alerta_alta_o2);
        setGabinete_otros_modbus_device_status(response.jsonData.estados.jsonData.gabinete.otros.modbus_device_status);
        setGabinete_otros_manual_auto(response.jsonData.estados.jsonData.gabinete.otros.manual_auto);
        setGabinete_otros_manual_auto_indicador(response.jsonData.estados.jsonData.gabinete.otros.manual_auto_indicador);
      }
      else{
        setSistema_estacion_vandalizada(response.jsonData.estados.jsonData.sistema.estacion_vandalizada);

        setDisabled_chemgard(response.jsonData.estados.jsonData.disabled.chemgard);

        setFalla_chemgard(response.jsonData.estados.jsonData.falla.chemgard);
        setFalla_sensor_alcantarillado_capacitivo(response.jsonData.estados.jsonData.falla.sensor_alcantarillado_capacitivo);
        setFalla_enable_bomba(response.jsonData.estados.jsonData.falla.enable_bomba);

        setEntrada_puente_chem(response.jsonData.estados.jsonData.entrada.puente_chem);
        setEntrada_puente_xe(response.jsonData.estados.jsonData.entrada.puente_xe);
        setEntrada_on_off_chemgard(response.jsonData.estados.jsonData.entrada.on_off_chemgard);
        setEntrada_reset_bombas(response.jsonData.estados.jsonData.entrada.reset_bombas);
        setEntrada_enable_electrovalvula(response.jsonData.estados.jsonData.entrada.enable_electrovalvula);
        setEntrada_enable_ventiladores(response.jsonData.estados.jsonData.entrada.enable_ventiladores);

        setGabinete_puerta_abierta_ups(response.jsonData.estados.jsonData.gabinete.puerta_abierta.ups);
        setGabinete_puerta_abierta_control(response.jsonData.estados.jsonData.gabinete.puerta_abierta.control);
        setGabinete_puerta_abierta_sensores(response.jsonData.estados.jsonData.gabinete.puerta_abierta.sensores);
        setGabinete_puerta_abierta_calibracion(response.jsonData.estados.jsonData.gabinete.puerta_abierta.calibracion);
        setGabinete_puerta_abierta_filtros(response.jsonData.estados.jsonData.gabinete.puerta_abierta.filtros);

        setGabinete_encendidas_bombas(response.jsonData.estados.jsonData.gabinete.encendidas.bombas);

        setGabinete_otros_mantenimiento(response.jsonData.estados.jsonData.gabinete.otros.mantenimiento);
        setGabinete_otros_ventilador(response.jsonData.estados.jsonData.gabinete.otros.ventilador);
        setGabinete_otros_capacitivo(response.jsonData.estados.jsonData.gabinete.otros.capacitivo);
        setGabinete_otros_bomba_obstruida(response.jsonData.estados.jsonData.gabinete.otros.bomba_obstruida);
        setGabinete_otros_alarma_puertas_abiertas(response.jsonData.estados.jsonData.gabinete.otros.alarma_puertas_abiertas);
        setGabinete_otros_nivel_agua(response.jsonData.estados.jsonData.gabinete.otros.nivel_agua);
        setGabinete_otros_electrovalvulas(response.jsonData.estados.jsonData.gabinete.otros.electrovalvulas);

        setLel_int(response.jsonData.monitoreo.jsonData.gas.lel_int);
        setFlujo(response.jsonData.monitoreo.jsonData.sistema.flujo);
        setNivel(response.jsonData.monitoreo.jsonData.sistema.nivel);

        // Si el PLC es SIEMENS
        if(response.plc === 'SIEMENS'){
          setFalla_bomba_xe(response.jsonData.estados.jsonData.falla.bomba_xe);
          setFalla_transmisor_1(response.jsonData.monitoreo.jsonData.falla.transmisor_1);
          setFalla_transmisor_2(response.jsonData.monitoreo.jsonData.falla.transmisor_2);
          
          setMissing_co(response.jsonData.monitoreo.jsonData.missing.co);
          setMissing_h2s(response.jsonData.monitoreo.jsonData.missing.h2s);
          setMissing_int_col(response.jsonData.monitoreo.jsonData.missing.int_col);
          setMissing_o2(response.jsonData.monitoreo.jsonData.missing.o2);
          setMissing_lel(response.jsonData.monitoreo.jsonData.missing.lel);

          setDisabled_lel(response.jsonData.monitoreo.jsonData.disabled.lel);
          setDisabled_int_col(response.jsonData.monitoreo.jsonData.disabled.int_col);
          setDisabled_o2(response.jsonData.monitoreo.jsonData.disabled.o2);
          setDisabled_co(response.jsonData.monitoreo.jsonData.disabled.co);
          setDisabled_h2s(response.jsonData.monitoreo.jsonData.disabled.h2s);
        }
        else{
          setFalla_sensor_voc(response.jsonData.estados.jsonData.falla.sensor_voc);
          setFalla_sensor_h2s(response.jsonData.estados.jsonData.falla.sensor_h2s);
          setFalla_sensor_lel(response.jsonData.estados.jsonData.falla.sensor_lel);
          setFalla_sensor_co(response.jsonData.estados.jsonData.falla.sensor_co);
          setFalla_sensor_o2(response.jsonData.estados.jsonData.falla.sensor_o2);
          setFalla_sensor_ammonia(response.jsonData.estados.jsonData.falla.sensor_ammonia);
      }
      
    }
      // Actualiza el estado con los datos de los gráficos
      setDataLEL((prevState) => [
        {
          id: 'LEL',
          data: [
            // Mantiene solo los últimos puntos de datos (maxPoints - 1)
            ...prevState[0].data.slice(-maxPoints),
            // Agrega un nuevo punto de datos con la hora actual y el valor de LEL recibido en la respuesta
            {
              x: new Date().toLocaleTimeString(),
              y: response.jsonData.monitoreo.jsonData.gas.lel,
            },
          ],
        },
      ]);


      setDataCO((prevState) => [
        {
          id: 'CO',
          data: [
            ...prevState[0].data.slice(-maxPoints),
            {
              x: new Date().toLocaleTimeString(),
              y: response.jsonData.monitoreo.jsonData.gas.co,
            },
          ],
        },
      ]);

      setDataO2((prevState) => [
        {
          id: 'O2',
          data: [
            ...prevState[0].data.slice(-maxPoints),
            {
              x: new Date().toLocaleTimeString(),
              y: response.jsonData.monitoreo.jsonData.gas.o2,
            },
          ],
        },
      ]);

      setDataVOCS((prevState) => [
        {
          id: 'VOCS',
          data: [
            ...prevState[0].data.slice(-maxPoints),
            {
              x: new Date().toLocaleTimeString(),
              y: response.jsonData.monitoreo.jsonData.gas.voc,
            },
          ],
        },
      ]);

      setDataH2S((prevState) => [
        {
          id: 'H2S',
          data: [
            ...prevState[0].data.slice(-maxPoints),
            {
              x: new Date().toLocaleTimeString(),
              y: response.jsonData.monitoreo.jsonData.gas.h2s,
            },
          ],
        },
      ]);
    })
    .catch(err => {
      console.log("fetch error" + err);
    }) 
  }

  // Usa useEffect para ejecutar checkDatos cada cierto tiempo
  useEffect(() => {
    const interval = setInterval(() => {
      checkDatos();
    }, (parseInt(sessionStorage.getItem('tiempo')))*1000);
    return () => clearInterval(interval);
  }, [co,o2,h2s,lel,voc]);


  return (
    <>
    <Box m="20px" display="flex" justifyContent="space-between" alignItems="center">
    <Header title={location.state?.name ? location.state.name : 'Ninguna estación seleccionada'} subtitle={plc} />
    </Box>
    <ChartContainer>
      
      {/* ROW 1 */}

        {/* Datos Meteorologicos
        {tipoplc === 'ALLEN BRADLEY' && (
          <Box
            gridColumn="span 4"
            gridRow="span 2"
            backgroundColor={colors.primary[400]}
            p="30px"
          >
            <Typography variant="h5" fontWeight="600">
              Meteorológico
            </Typography>
            <Box display="flex" flexDirection="column" alignItems="center" mt="25px">
              <Table sx={{ minWidth: 200 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {newrows.map((newrows) => (
                    <TableRow
                      key={newrows.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {newrows.name}
                      </TableCell>
                      <TableCell align="right">{newrows.stat}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Box>
        )}
          */}

        {/* Gráficas */}

        {/* Gráfica de LEL */}
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              {/* Título de la gráfica */}
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Niveles
              </Typography>
              
              {/* Subtítulo de la gráfica */}
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                LEL
              </Typography>
            </Box>
            
            <Box>
              {/* Valor actual de LEL */}
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                {lel < -90 ? "NA" : (lel === undefined ? "UN" : (lel === null ? "NULL" : Math.floor(lel * 100) / 100 + "%"))}
              </Typography>
            </Box>
          </Box>
          
          {/* Renderizado de la gráfica de LEL */}
          <Box height="250px" m="-20px 0 0 0">
            <LineChart data={dataLEL} min={0} max={100} legend={'%'}/>
          </Box>
        </Box>

        {/* Gráfica de O2 */}
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              {/* Título de la gráfica */}
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Niveles
              </Typography>
              
              {/* Subtítulo de la gráfica */}
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                O2
              </Typography>
            </Box>
            
            <Box>
              {/* Valor actual de O2 */}
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
              {o2 < -90 ? "NA" : (o2 === undefined ? "UN" : (o2 === null ? "NULL" : Math.floor(o2 * 100) / 100 + "%"))}
              </Typography>
            </Box>
          </Box>
          
          {/* Renderizado de la Gráfica de O2 */}
          <Box height="250px" m="-20px 0 0 0">
            <LineChart data={dataO2} min={0} max={100} legend={'%'}/>
          </Box>
        </Box>

        {/* Grafica de CO*/}

        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          ><Box>
          <Typography
            variant="h5"
            fontWeight="600"
            color={colors.grey[100]}
          >
            Niveles
          </Typography>
          <Typography
            variant="h3"
            fontWeight="bold"
            color={colors.greenAccent[500]}
          >
            CO
          </Typography>
        </Box>
        <Box>
        <Typography
            variant="h3"
            fontWeight="bold"
            color={colors.greenAccent[500]}
          >
            {co < -90 ? "NA" : (co === undefined ? "UN" : (co === null ? "NULL" : Math.floor(co * 100) / 100 + "PPM"))}
          </Typography>
        </Box>
      </Box>
          <Box height="250px" m="-20px 0 0 0">
            <LineChart data={dataCO} min={0} max={200} legend={'PPM'} />
          </Box>
        </Box>

        {/* Grafica de VOCS*/}

        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          ><Box>
          <Typography
            variant="h5"
            fontWeight="600"
            color={colors.grey[100]}
          >
            Niveles
          </Typography>
          <Typography
            variant="h3"
            fontWeight="bold"
            color={colors.greenAccent[500]}
          >
            VOCS
          </Typography>
        </Box>
        <Box>
        <Typography
            variant="h3"
            fontWeight="bold"
            color={colors.greenAccent[500]}
          >
            {voc < -90 ? "NA" : (voc === undefined ? "UN" : (voc === null ? "NULL" : Math.floor(voc * 100) / 100 + "PPM"))}
          </Typography>
        </Box>
      </Box>
          <Box height="250px" m="-20px 0 0 0">
            <LineChart data={dataVOCS} min={0} max={200} legend={'PPM'} />
          </Box>
        </Box>

        {/* Grafica de H2S*/}

        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          ><Box>
          <Typography
            variant="h5"
            fontWeight="600"
            color={colors.grey[100]}
          >
            Niveles
          </Typography>
          <Typography
            variant="h3"
            fontWeight="bold"
            color={colors.greenAccent[500]}
          >
            H2S
          </Typography>
        </Box>
        <Box>
        <Typography
            variant="h3"
            fontWeight="bold"
            color={colors.greenAccent[500]}
          >
            {h2s < -90 ? "NA" : (h2s === undefined ? "UN" : (h2s === null ? "NULL" : Math.floor(h2s * 100) / 100 + "PPM"))}
          </Typography>
        </Box>
      </Box>
          <Box height="250px" m="-20px 0 0 0">
            <LineChart data={dataH2S} min={0} max={200} legend={'PPM'} />
          </Box>
        </Box>


        {/* Datos de Gases */}
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p="30px"
        >
          {/* Título de la sección */}
          <Typography variant="h5" fontWeight="400">
            Gas
          </Typography>
          
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px"
          >
            {/* Tabla para mostrar los datos de los gases */}
            <Table sx={{ minWidth: 200 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Muestra una fila en la tabla para cada elemento en el array rows */}
                {rows.map((rows) => (
                  <TableRow
                    key={rows.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {rows.name}
                    </TableCell>
                    <TableCell align="right">{rows.stat}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Box>


        {/* Sistemas */}
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            {/* Título de la sección */}
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Sistemas
            </Typography>
          </Box>
          
          {/* Tabla para mostrar los datos de los sistemas */}
          <TableContainer component={Paper} >
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Dispositivo</TableCell>
                  <TableCell align="right">Estado</TableCell>
                  {/* Muestra la columna Control si el usuario tiene permisos */}
                  {(permisos.includes(1) || permisos.includes(5)) && (
                    <TableCell align="right">Control</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Muestra una fila en la tabla para cada elemento en el array rows1 */}
                {rows1.map((row) => (
                  <TableRow key={row.dispositivo}>
                    <TableCell component="th" scope="row">
                      {row.dispositivo}
                    </TableCell>
                    <TableCell align="right">
                      {/* Muestra un ícono de verificación o de cierre según el estado del dispositivo */}
                      {row.estado ?   <CloseIcon /> : <CheckIcon />}
                    </TableCell>
                    {/* Muestra la celda Control si el usuario tiene permisos */}
                    {(permisos.includes(1) || permisos.includes(5)) && (
                      <TableCell align="right">
                        {/* Muestra un switch si el dispositivo es BOMBA o NIVEL */}
                        {(row.dispositivo === "BOMBA" || row.dispositivo === "NIVEL") &&(
                          <Switch />
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        {/* Temperatura */}
        <Box
          gridColumn="span 2"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          backgroundColor={colors.primary[400]}
          p="0 30px"
        >
          <Box>
            {/* Título de la sección */}
            <Typography
              variant="h5"
              fontWeight="600"
              color={colors.grey[100]}
            >
              Temperatura
            </Typography>
          </Box>

          {/* Valor actual de la temperatura */}
          <Typography
            variant="h3"
            fontWeight="bold"
            color={colors.greenAccent[500]}
          >
            {temp < -90 ? "NA" : (temp === undefined ? "UN" : (temp === null ? "NULL" : Math.floor(temp * 100) / 100 + "°C"))}
          </Typography>
        </Box>

    </ChartContainer>
    </>
  
  );
};

export default Dashboard;