// Importa componentes de Material UI para la interfaz de usuario
import { Box, Button, TextField } from "@mui/material";
// Importa Formik para manejar formularios
import { Formik } from "formik";
// Importa Yup para validar formularios
import * as yup from "yup";
// Importa useMediaQuery de Material UI para detectar el tamaño de la pantalla
import useMediaQuery from "@mui/material/useMediaQuery";
// Importa el componente Header personalizado
import Header from "../../components/Header";
// Importa useNavigate de react-router-dom para navegar entre páginas
import { useNavigate } from 'react-router-dom';

// Importa useState de React para manejar el estado del componente
import {useState } from "react";

// Importa componentes de Material UI para mostrar/ocultar la contraseña
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// Importa sha256 de crypto-hash para encriptar contraseñas
import {sha256} from 'crypto-hash';

// Importa componentes de Material UI para mostrar un modal
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

// Importa urls desde un archivo de datos local
import { urls } from "../../data/urls";

// Define un componente Modal que muestra un mensaje y tiene un botón para cerrarlo
function Modal({ message, onClose }) {
  return (
    <Dialog open={true} onClose={onClose}>
      <DialogContent>
        <p>{message}</p>
      </DialogContent>
    </Dialog>
  );
} 

// Define el componente Login
const Login = () => {
  // Usa useMediaQuery para detectar si el dispositivo es móvil o no
  const isNonMobile = useMediaQuery("(min-width:600px)");
  // Usa useNavigate para navegar entre páginas
  const navigate = useNavigate();
  // Limpia el almacenamiento de sesión al cargar el componente
  sessionStorage.clear();

  // Controladores de estados de la contraseña
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  // Controladores de estados del modal
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState('');
  
  

  /**
   * Verifica si el JWT almacenado en sessionStorage es válido.
   * Si es válido, navega a la página de inicio.
   * Si no es válido, muestra un modal con un mensaje de error.
   */
  const checkJWT = () => {
    // Realiza una solicitud GET a la URL especificada en urls.verify
    fetch(urls.verify, {
      method: 'GET',
      headers: {
        // Incluye el JWT almacenado en sessionStorage como un encabezado de solicitud
        'accessToken': sessionStorage.getItem('JWT')
      },
    })
    .then((response) => response.json())
    .then(response => {
      // Si la respuesta contiene la propiedad 'verified'
      if(response.hasOwnProperty('verified')){
        // Navega a la página de inicio
        navigate('/inicio');
      } else {
        // Si no, muestra un modal con un mensaje de error
        setMessage("Error de comunicación: ");
        setShowModal(true);
      };
    })
    .catch(err => {
      // Si ocurre un error durante la solicitud, muestra un modal con un mensaje de error
      setMessage("Error de comunicación: " + err);
      setShowModal(true);
    })
  }


  /**
   * Maneja el envío del formulario de inicio de sesión.
   * Recibe los valores del formulario y verifica si el usuario está en la base de datos.
   * Si el usuario es válido, genera un JWT y da acceso a la sesión.
   * Si el usuario no es válido, muestra un modal con un mensaje de error.
   */
  const handleFormSubmit = async (values) => {
    // Encripta la contraseña con sha256
    const result = await sha256(values.password);
    
    // Realiza una solicitud POST a la URL especificada en urls.auth
    fetch(urls.auth , {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        nombre: values.userName,
        pass: result
      })
    })
    .then((response) => response.json())
    .then(response => {
      // Si la respuesta contiene un accessToken
      if(response.hasOwnProperty('accessToken')){
        // Vacía y rellena el almacenamiento de sesión
        sessionStorage.clear();
        sessionStorage.setItem('JWT', response.accessToken);
        sessionStorage.setItem('nombre', values.userName);
        sessionStorage.setItem('rol', response.rol);
        sessionStorage.setItem('tiempo', response.tiempo);
        const jsonArray = Object.values(response.permisos);
        sessionStorage.setItem('permisos', JSON.stringify(jsonArray[0]));
        
        // Verifica si el JWT es válido
        checkJWT();
      } else {
        // Si no contiene un accessToken, muestra un modal con un mensaje de error
        setMessage(response.message);
        setShowModal(true);
      };
    })
    .catch( err => {
      //console.log("fetch error" + err);
      setMessage("Error de comunicación: " + err);
      setShowModal(true);
    })
  };

  return (
    <Box m="20px">
      {/* Renderiza el componente Header con el título y subtítulo especificados */}
      <Header title="Login" subtitle="Entra a tu perfil" />

      {/* Renderiza el formulario de inicio de sesión utilizando Formik */}
      <Formik
        // Establece la función que se ejecutará al enviar el formulario
        onSubmit={handleFormSubmit}
        // Establece los valores iniciales del formulario
        initialValues={initialValues}
        // Establece el esquema de validación del formulario
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              {/* Campo de texto para ingresar el nombre de usuario */}
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Usuario"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.userName}
                name="userName"
                error={!!touched.userName && !!errors.userName}
                helperText={touched.userName && errors.userName}
                sx={{ gridColumn: "span 2" }}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
              />
              
              {/* Campo de texto para ingresar la contraseña */}
              <TextField
                fullWidth
                variant="filled"
                type={showPassword ? "text" : "password"}
                label="Contraseña"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                name="password"
                error={!!touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                
                // Agrega un botón para mostrar/ocultar la contraseña
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            
            {/* Botón para enviar el formulario */}
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                login
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      
      {/* Renderiza un modal si showModal es verdadero */}
      {showModal && (
        <Modal 
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          message={message} onClose={() => setShowModal(false)}
        />
      )}
    </Box>
  );
    };

// Esquema de validación del formulario utilizando Yup
const checkoutSchema = yup.object().shape({
  userName: yup.string().required("required"),
  password: yup.string().required("required"),
});

// Valores iniciales del formulario
const initialValues = {
  userName: "",
  password: "",
};

export default Login;
