import React, { useEffect, useState } from "react";
import {useNavigate } from "react-router-dom";

import Topbar from "../scenes/global/Topbar";
import Sidebar from "../scenes/global/Sidebar";

import { urls } from "../data/urls";

const ProtectedRoute = (props) => {
    const [isSidebar, setIsSidebar] = useState(true);
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(null);

    const checkUserToken = async () => {
      //setIsLoggedIn(true);
    
      await fetch(urls.verify, {
          
          method: 'GET',
          headers: {
            accesstoken: sessionStorage.getItem('JWT')
          },
        })
        
        .then((response) => response.json())
        .then(response => {

          if(response.hasOwnProperty('verified')){
            setIsLoggedIn(true);
          }
          else{
            return navigate('/');
          };
          //console.log(response);
          
        })

        .catch(err => {
          navigate('/');
          console.log("fetch error" + err);
        })
        
        
    }
    
    useEffect(() => {
        const interval = setInterval(() => {
          checkUserToken();
        }, (parseInt(sessionStorage.getItem('tiempo')))*1000);
        return () => clearInterval(interval);
      }, []);
      
      if (isLoggedIn === null) {
        return <div>Loading...</div>;
      }

      return (     
        <>
      {isLoggedIn ? (
        <div className="protected">
          <Sidebar isSidebar={isSidebar}/>
          <main className="content">
            <Topbar setIsSidebar={setIsSidebar}/> 
            {props.children}
          </main>
        </div>
      ) : null}
    </>
      );
}
export default ProtectedRoute;
