import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ allowedRoles, children }) => {
  const userPermissions = JSON.parse(sessionStorage.getItem('permisos'));

  const isAllowed = userPermissions.some(permission =>
    allowedRoles.includes(permission)
  );

  if (isAllowed) {
    return children;
  } else {
    return <Navigate to="/inicio" />;
  }
};
export default PrivateRoute;