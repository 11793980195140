// Importa componentes de Material UI para la interfaz de usuario
import { Box, Typography, useTheme, IconButton } from "@mui/material";
// Importa DataGrid de Material UI para mostrar una tabla
import { DataGrid } from "@mui/x-data-grid";
// Importa tokens desde el archivo de tema local
import { tokens } from "../../theme";
// Importa íconos de Material UI para mostrar en la interfaz de usuario
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
// Importa el componente Header personalizado
import Header from "../../components/Header";
// Importa useEffect y useState de React para manejar el estado y los efectos del componente
import React, { useEffect, useState } from "react";
// Importa íconos adicionales de Material UI para mostrar en la interfaz de usuario
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
// Importa useNavigate de react-router-dom para navegar entre páginas
import { useNavigate } from 'react-router-dom';
// Importa componentes de Material UI para mostrar un modal
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
// Importa urls desde un archivo de datos local
import { urls } from "../../data/urls";

// Define un componente Modal que muestra un mensaje y tiene un botón para cerrarlo
function Modal({ message, onClose }) {
  return (
    <Dialog open={true} onClose={onClose}>
      <DialogContent>
        <p>{message}</p>
      </DialogContent>
    </Dialog>
  );
}


// Define el componente Team
const ConsultarUsuarios = () => {
    // Usa useNavigate para navegar entre páginas
    const navigate = useNavigate();
    // Usa useTheme para acceder al tema actual
    const theme = useTheme();
    // Usa tokens para acceder a los colores del tema
    const colors = tokens(theme.palette.mode);
    // Obtiene los permisos del usuario desde sessionStorage
    const permisos = JSON.parse(sessionStorage.getItem('permisos'));
    // Define el estado para almacenar la lista de usuarios
    const [users, setUsers] = useState([]);
    // Define el estado para almacenar el mensaje del modal
    const [message, setMessage] = useState('');
    // Define el estado para controlar la visibilidad del modal
    const [showModal, setShowModal] = useState(false);

    // Define una función para eliminar un usuario por su ID
    const deleteUser = (id) => {
      fetch(urls.usersDeleteId(id), {
        method: 'DELETE',
        headers: {
          accesstoken: sessionStorage.getItem('JWT')
        },
      })
      .then((response) => response.json())
      .then(response => {
        setMessage(JSON.stringify(response.message));
        setShowModal(true);
        checkUsers();
      })
      .catch(err => {
        setMessage("Error de comunicación: " + err);
        setShowModal(true);
      })
    }

    // Define una función para obtener la lista de usuarios de la base de datos
    const checkUsers = () => {
      fetch(urls.usersGetAll, {
        method: 'GET',
        headers: {
          accesstoken: sessionStorage.getItem('JWT')
        },
      })
      .then((response) => response.json())
      .then(response => {
        let data = response.listUsers;
        // Si el usuario no tiene permisos de super administrador, filtra la lista de usuarios para excluir a los super administradores
        if (!permisos.includes(1)){
          data = data.filter(user => user.id_rol !== "1");
        }
        setUsers(data);
      })
      .catch(err => {
        console.log("fetch error" + err);
      })
    }


    //Funcion que imprime el rol del usuario.
    function RoleDisplay(rol) {
      let role;
      if (rol === '1') {
          role = 'Superadmin';
      } else if (rol === '2') {
          role = 'Administrador';
      } else if (rol === '3') {
          role = 'Operador A';
      } else if (rol === '4') {
        role = 'Operador B';
      } else if (rol === '5') {
        role = 'Visor';
    }
      return <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>{role}</Typography>;
    }

    // Define las columnas del DataGrid
    const columns = [
      {
        field: "nombre",
        headerName: "Nombre",
        flex: 1,
        cellClassName: "name-column--cell",
      },
      {
        field: "login",
        headerName: "Nombre de usuario",
        flex: 1,
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1,
      },
      {
        field: "id_rol",
        headerName: "Rol de usuario",
        flex: 1,
        // Renderiza una celda personalizada para mostrar el rol de usuario con un ícono y un color de fondo
        renderCell: ({ row: { id_rol } }) => {
          return (
            <Box
              width="60%"
              m="0 auto"
              p="5px"
              display="flex"
              justifyContent="center"
              backgroundColor={
                id_rol === '1'
                  ? colors.greenAccent[600]
                  : id_rol === "manager"
                  ? colors.greenAccent[700]
                  : colors.greenAccent[700]
              }
              borderRadius="4px"
            >
              {/* Muestra un ícono diferente según el rol de usuario */}
              {id_rol === '1' && <AdminPanelSettingsOutlinedIcon />}
              {id_rol === '2' && <SecurityOutlinedIcon />}
              {id_rol === '3' && <LockOpenOutlinedIcon />}
              {id_rol === '4' && <LockOpenOutlinedIcon />}
              {id_rol === '5' && <VisibilityOutlinedIcon />}
              {/* Muestra el nombre del rol de usuario */}
              {RoleDisplay(id_rol)}
            </Box>
          );
        },
      },
      {
        field: "modificar",
        headerName: "Modificar usuario",
        // Renderiza una celda personalizada para mostrar un botón para modificar al usuario
        renderCell: ({ row: { id_usuario, id_rol } }) => {
          // No muestra el botón si el usuario fila es un super administrador
          if (id_rol === '1' ) {
            return null;
          } else {
            return (
              <IconButton onClick={() => { navigate('/usuarios/actualizar', { state: { id: id_usuario } }) }}>
                <ModeEditOutlineOutlinedIcon />
              </IconButton>
            );
          }
        },
      },
      {
        field: "borrar",
        headerName: "Borrar usuario",
        // Renderiza una celda personalizada para mostrar un botón para borrar al usuario
        renderCell: ({ row: { id_usuario, id_rol } }) => {
          // No muestra el botón si el usuario es un administrador
          if (id_rol === '1' ) {
            return null;
          } else {
            return (
              <IconButton onClick={() => { deleteUser(id_usuario) }}>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            );
          }
        },
      },
    ];


  //El useEffect le dice a react que debe realizar algo despues de renderizar  
  useEffect(() => {
    checkUsers();
  }, [] );

  return (
    <Box m="20px">
      <Header title="CONSULTA DE USUARIOS" subtitle="Consulta a tu equipo de trabajo." />
      <Box
        m="40px 0 0 0"
        height="60vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        {
          // Creacion del data grid en react, usa las columnas declaras con anterioridad y los datos de los usuarios como filas
        }
        <DataGrid getRowId={(row) => row.id_usuario} rows={users} columns={columns} />
        {showModal && <Modal 
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        message={message} onClose={() => setShowModal(false)} />}
      </Box>
    </Box>
  );
};

export default ConsultarUsuarios;
