import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";

import * as React from 'react';
import {useState } from "react";

//Componentes para el texto del modal
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
//
import { urls } from "../../data/urls";

function Modal({ message, onClose }) {
  return (
    
    <Dialog open={true} onClose={onClose}>
      <DialogContent>
        <p>{message}</p>
      </DialogContent>
    </Dialog>
  );
}

const FormUnidad = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  //Controladores de estados del modal
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState('');
  //


  const handleFormSubmit = async (values) => {
    // sessionStorage.setItem('nombre', values.userName); //cuando no sean pruebas hay que quitarlo
    
    
    
    fetch(urls.configUnidadMedida, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        accesstoken: sessionStorage.getItem('JWT')
      },
      body: JSON.stringify({
        unidad: values.unidad,
      })
    })
    .then((response) => response.json())
    .then(response => {
      //console.log(response);
      setMessage(JSON.stringify(response.message));
      setShowModal(true);
    })
    .catch(err => {
      //console.log("fetch error" + err);
      setMessage("Error de comunicación: " + err);
      setShowModal(true);
    })
    
   setMessage("Se ha creado correctamente el nuevo registro.");
   setShowModal(true);
   
   //console.log(values);
  };

  return (
    <Box m="20px">
      <Header title="CREAR UNIDAD" subtitle="Crear un nuevo registro en Unidad" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="unidad"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.unidad}
                name="unidad"
                error={!!touched.unidad && !!errors.unidad}
                helperText={touched.unidad && errors.unidad}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64 }}
              />



            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Crear Nuevo
              </Button>
            </Box>
          </form>
        )}
      </Formik>

      {showModal && <Modal 
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        message={message} onClose={() => setShowModal(false)} />}



    </Box>
  );
};



const checkoutSchema = yup.object().shape({
  unidad: yup.string().required("required"),
});
const initialValues = {
  unidad: "",
};

export default FormUnidad;
