import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";

import * as React from 'react';
import { useEffect, useState } from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

//Componentes para el texto del modal
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
//

//Componentes para la visibilidad on/off de la contraseña
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
//

import {useLocation} from 'react-router-dom';

import ReactJson from 'react-json-view';

import { urls } from "../../data/urls";


function Modal({ message, onClose }) {
  return (
    
    <Dialog open={true} onClose={onClose}>
      <DialogContent>
        <p>{message}</p>
      </DialogContent>
    </Dialog>
  );
}

const UpdateTipo = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  //Controladores de estados del modal
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState('');
  //


  const [estacion, setEstacion] = useState();
  const [my_json_object, setMy_json_object] = useState();

  
  const location = useLocation();

  
  const checkEstacion = () => {
    
    fetch(urls.configTipoEquipoId(location.state?.id), {
      
      method: 'GET',
      headers: {
        accesstoken: sessionStorage.getItem('JWT')
      },
    })
    
    .then((response) => response.json())
    .then(response => {
      
      //console.log(data.nombre)
      setEstacion(response);
    })
    .catch(err => {
      console.log("fetch error" + err);
    })
  }

  const initialValues = {
    nombre: estacion?.nombre ?? "",
    alias: estacion?.login ?? "",
    email: estacion?.email ?? "",
    longitud: estacion?.longitud ?? "",
    latitud: estacion?.latitud ?? "",
    id_tipo_equipo: estacion?.id_tipo_equipo ?? "",
    id_estado_operacion: estacion?.id_estado_operacion ?? "",
    id_modo_operacion: estacion?.id_modo_operacion ?? "",
  };

  const handleFormSubmit = async (values) => {
    // sessionStorage.setItem('nombre', values.alias); //cuando no sean pruebas hay que quitarlo
    //console.log(result);
    fetch(urls.configEstacionId(location.state?.id), {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        accesstoken: sessionStorage.getItem('JWT')
      },
      body: JSON.stringify({
        nombre: values.nombre,
        alias: values.alias,
        email: values.email,
        longitud: values.longitud,
        latitud: values.latitud,
        id_tipo_equipo: values.id_tipo_equipo,
        id_estado_operacion: values.id_estado_operacion,
        id_modo_operacion: values.id_modo_operacion,
        
      })
    })
    .then((response) => response.json())
    .then(response => {
      //console.log(response);
      setMessage(JSON.stringify(response.message));
      setShowModal(true);
    })
    .catch(err => {
      //console.log("fetch error" + err);
      setMessage("Error de comunicación: " + err);
      setShowModal(true);
    })
    
   setMessage("La estacion: "+ JSON.stringify(values.alias) + " se ha actualizado correctamente.");
   setShowModal(true);
   
   console.log(values);
  };

  useEffect(() => {
    checkEstacion();
  }, [] );

  return (
    <Box m="20px">
      <Header title="ACTUALIZAR Estacion" subtitle="Introduce los nuevos datos de la estacion" />
      <ReactJson src={estacion} theme={'paraiso'} />
    </Box>
  );
};



const checkoutSchema = yup.object().shape({
  nombre: yup.string().required("required"),
  alias: yup.string().required("required"),
  email_alarmas: yup.string().email("invalid email").required("required"),
  latitud: yup.number().required("required"),
  longitud: yup.number().required("required"),
  id_estado_operacion: yup.number().integer().positive().required("required"),
  id_modo_operacion: yup.number().integer().positive().required("required"),
  id_tipo_equipo: yup.number().integer().positive().required("required"),
});


export default UpdateTipo;
