import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";

import * as React from 'react';
import {useState } from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

//Componentes para el texto del modal
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
//

//Componentes para la visibilidad on/off de la contraseña
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import {sha256} from 'crypto-hash';
//

import { urls } from "../../data/urls";

function Modal({ message, onClose }) {
  return (
    
    <Dialog open={true} onClose={onClose}>
      <DialogContent>
        <p>{message}</p>
      </DialogContent>
    </Dialog>
  );
}

const FormSensor = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  //Controladores de estados del modal
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState('');
  //

  //Controladores de estados de la contraseña
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  //


  const handleFormSubmit = async (values) => {
    // sessionStorage.setItem('nombre', values.userName); //cuando no sean pruebas hay que quitarlo
    
    
    
    fetch(urls.configTipoSensor, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        accesstoken: sessionStorage.getItem('JWT')
      },
      body: JSON.stringify({
        descripcion: values.descripcion,
        elemento: values.elemento,
        id_tipo_sensor: values.id_tipo_sensor,
        valor_tipo_sensor: values.valor_tipo_sensor, 
      })
    })
    .then((response) => response.json())
    .then(response => {
      //console.log(response);
      setMessage(JSON.stringify(response.message));
      setShowModal(true);
    })
    .catch(err => {
      //console.log("fetch error" + err);
      setMessage("Error de comunicación: " + err);
      setShowModal(true);
    })
    
   setMessage("Se ha creado correctamente el nuevo registro.");
   setShowModal(true);
   
   //console.log(values);
  };

  return (
    <Box m="20px">
      <Header title="CREAR SENSOR" subtitle="Crear un nuevo registro en Sensor" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="descripcion"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.descripcion}
                name="descripcion"
                error={!!touched.descripcion && !!errors.descripcion}
                helperText={touched.descripcion && errors.descripcion}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64 }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="elemento"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.elemento}
                name="elemento"
                error={!!touched.elemento && !!errors.elemento}
                helperText={touched.elemento && errors.elemento}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64 }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="id_tipo_sensor"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.id_tipo_sensor}
                name="id_tipo_sensor"
                error={!!touched.id_tipo_sensor && !!errors.id_tipo_sensor}
                helperText={touched.id_tipo_sensor && errors.id_tipo_sensor}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64 }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="valor_tipo_sensor"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.valor_tipo_sensor}
                name="valor_tipo_sensor"
                error={!!touched.valor_tipo_sensor && !!errors.valor_tipo_sensor}
                helperText={touched.valor_tipo_sensor && errors.valor_tipo_sensor}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64 }}
              />



            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Crear Nuevo
              </Button>
            </Box>
          </form>
        )}
      </Formik>

      {showModal && <Modal 
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        message={message} onClose={() => setShowModal(false)} />}



    </Box>
  );
};



const checkoutSchema = yup.object().shape({
  descripcion:yup.string().required("required"),
  elemento: yup.string().required("required"),
  id_tipo_sensor: yup.number().required("required"),
  valor_tipo_sensor: yup.number().required("required"),
  
});
const initialValues = {
  descripcion:"",
  elemento: "",
  id_tipo_sensor: "",
  valor_tipo_sensor: "",
};

export default FormSensor;
