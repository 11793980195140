import React, { useState, useEffect } from 'react';
import { Formik, Form, useFormikContext } from 'formik';
import TextField from '@mui/material/TextField';


import Header from "../../components/Header";
//Componentes para el texto del modal
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
//

import { urls } from "../../data/urls";

function Modal({ message, onClose }) {
  return (
    
    <Dialog open={true} onClose={onClose}>
      <DialogContent>
        <p>{message}</p>
      </DialogContent>
    </Dialog>
  );
} 

function FormikTextField({ name, ...props }) {
  const { values, setFieldValue } = useFormikContext();
  return (
    <TextField
      name={name}
      value={values[name]}
      onChange={event => setFieldValue(name, event.target.value)}
      {...props}
    />
  );
}

function Gas() {
  const [data, setData] = useState([]);
  //Controladores de estados del modal
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState('');
  //

  useEffect(() => {
    fetch(urls.configGas, {
      
      method: 'GET',
      headers: {
        accesstoken: sessionStorage.getItem('JWT')
      },
    })
    
    .then((response) => response.json())
    .then(data => {
      data.sort((a, b) => a.id_gas - b.id_gas);
      //console.log(data);
      setData(data);
    })
    .catch(err => {
      setMessage("Error de comunicación: " + err);
      setShowModal(true);
    })
  }, []);

  return (
    <>
    <div className="form-container">
      {data.map(item => (
        <React.Fragment key={item.id_gas}> 
        <div className="form-wrapper">
          <Header title={item.nombre} />
          <Formik
          
            initialValues={item}
            onSubmit={values => {
              const updatedValues = {
                ...values,
                default_alarma_alta_inferior: Number(values.default_alarma_alta_inferior),
                default_alarma_alta_superior: Number(values.default_alarma_alta_superior),
                default_alarma_media_inferior: Number(values.default_alarma_media_inferior),
                default_alarma_media_superior: Number(values.default_alarma_media_superior),
              };
            
              fetch(urls.configGasId(values.nombre), {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                  accesstoken: sessionStorage.getItem('JWT')
                },
                body: JSON.stringify(updatedValues)
              })
              .then(response => response.json())
              .then(response => {
                setMessage(response.message);
                setShowModal(true);
              })
              .catch(err => {
                setMessage("Error de comunicación: " + err);
                setShowModal(true);
              });
            }}
          >
            <Form>
              <FormikTextField name="nombre" label="nombre" />
              <br />
              <FormikTextField name="default_alarma_alta_inferior" label="default_alarma_alta_inferior" type="number" />
              <br />
              <FormikTextField name="default_alarma_alta_superior" label="default_alarma_alta_superior" type="number" />
              <br />
              <FormikTextField name="default_alarma_media_inferior" label="default_alarma_media_inferior" type="number" />
              <br />
              <FormikTextField name="default_alarma_media_superior" label="default_alarma_media_superior" type="number" />
              <br />
              <button type="submit">Guardar</button>
            </Form>
          </Formik>
          {showModal && <Modal 
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        message={message} onClose={() => setShowModal(false)} />}
          </div>
          </React.Fragment>
      ))}
      </div>
    </>
  );
}

export default Gas;
