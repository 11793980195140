import { Box, Button, Typography, useTheme, Switch  } from "@mui/material";
import { tokens } from "../../theme";
//import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import Header from "../../components/Header";

import 'leaflet/dist/leaflet.css';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import TablaAlarmas from "../global/TablaAlarmas";
import { DataGrid } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';

import { Stack } from '@mui/material';
import { urls } from "../../data/urls";

const AlarmasSistema = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [alarms, setAlarms] = useState([]);
  const [tabla, setTabla] = useState([]);
  const [processedData, setProcessedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const userPermissions = JSON.parse(sessionStorage.getItem('permisos'));
  const columns = [
    { field: 'fecha_disparo', headerName: 'Fecha', flex: 1, },
    { field: 'estacion', headerName: 'Estacion', flex: 1,},
    { field: 'sensors', headerName: 'Sensores deshabilitados', width: 200 },
    ...(userPermissions.includes(1) || userPermissions.includes(4)
    ? [
      {
        field: 'reconocida',
        headerName: 'Reconocida',
        flex: 1,
        renderCell: (params) => (
          <Button
          
            onClick={() => {
              // Llama a tu función aquí con el nuevo valor
              // Por ejemplo:
              // miFuncion(event.target.checked);
                fetch(urls.alarmasReconocida, {
                  method: 'PUT',
                  headers: {
                    'Content-Type': 'application/json',
                    accesstoken: sessionStorage.getItem('JWT')
                  },
                  body: JSON.stringify({
                    id_alarma: params.row.id_alarma_estado_sistema,
                    tipoAlarma: tabla,
                    estacion: params.row.alias,
                  })
                })
               
                .catch(err => {
                  console.log("fetch error" + err);
                })
            }}
            style={{ backgroundColor: colors.greenAccent[600] }}
          >
            Reconocer
          </Button>
        ),
      }
      ]
    : [])
  ];
  
  //console.log(alarms);

  const rows = processedData?.map((alarm, index) => ({
    id: index,
    fecha_disparo: alarm.fecha_disparo,
    estacion: alarm.estacion,
    reconocida: alarm.reconocida,
    id_alarma_estado_sistema: alarm.id_alarma_estado_sistema,
    alias:alarm.alias,
    sensors: alarm.sensors.join(', ')
  }));

  async function fetchData() {
    try {
      const response = await fetch(urls.alarmasSistema, {
        method: 'GET',
        headers: {
          accesstoken: sessionStorage.getItem('JWT')
        },
      });
      const data = await response.json();
      //console.log(data);
      setAlarms(data.alarma);
      setTabla(data.tabla);
      setLoading(false);
  
      // Procesar los datos de cada estación
      const sensores = data.alarma.map(station => {
        let sensors = [];
          if (station.estacion_fuera) sensors.push('estacion_fuera');
          if (station.mod_mantenimiento_1) sensors.push('mod_mantenimiento_1');
          if (station.estacion_vandalizada) sensors.push('estacion_vandalizada');
          if (station.mod_mantenimiento_2) sensors.push('mod_mantenimiento_2');
        return { ...station, sensors };
      });

      setProcessedData(sensores);
  
      // Aquí puedes utilizar processedData para mostrar los datos en el datagrid
    } catch (err) {
      console.log("error" + err);
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, (parseInt(sessionStorage.getItem('tiempo')))*1000);
  
    return () => clearInterval(interval);
  }, []);

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Alarmas" subtitle="Bienvenido a tus alarmas" />

        {/*
        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box>
          */}
      </Box>

      {/* GRID & CHARTS */}
      
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        
        gap="20px"
      >
        {/* ROW 1 */}

        {/* Box Alarma 1 */}
        
        {/*
        <Box
          gridColumn="span 6"
          gridRow="span 3"
          backgroundColor={'#F2A962'}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mt="25px"
            p="0 30px"
        >
          <Box>
          <EmailIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
          />
          <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Alarmas Medias
          </Typography>
          </Box>
              
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
                
              >
                0
              </Typography>
        </Box>
        */}

        {/* Box Alarma 2 */}
        {/*
        <Box
          gridColumn="span 6"
          gridRow="span 3"
          backgroundColor={'#F26263'}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mt="25px"
            p="0 30px"
        >
          <Box>
          <EmailIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
          />
          <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Alarmas Altas
          </Typography>
          </Box>
              
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
                
              >
                0
              </Typography>
        </Box>
      */}
        

        {/* ROW 2 */}
        
        

        {/* ROW 3 */}
        
        
      </Box>
      
      <Box
        m="40px 0 0 0"
        height="60vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
       <DataGrid
      rows={rows}
      columns={columns}
      loading={loading}
      components={{
        NoRowsOverlay: () => (
          <Stack height="100%" alignItems="center" justifyContent="center">
            No hay resultados
          </Stack>
        ),
        NoResultsOverlay: () => (
          <Stack height="100%" alignItems="center" justifyContent="center">
            Local filter returns no result
          </Stack>
        ),
      }}
    />
      </Box>
    </Box>
  );
};

export default AlarmasSistema;
