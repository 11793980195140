import React, { useState, useEffect } from 'react';
import { Formik, Form, useFormikContext } from 'formik';
import TextField from '@mui/material/TextField';



//Componentes para el texto del modal
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
//

import { urls } from "../../data/urls";

function Modal({ message, onClose }) {
  return (
    
    <Dialog open={true} onClose={onClose}>
      <DialogContent>
        <p>{message}</p>
      </DialogContent>
    </Dialog>
  );
} 


function FormikTextField({ name, ...props }) {
  const { values, setFieldValue } = useFormikContext();
  return (
    <TextField
      name={name}
      value={values[name]}
      onChange={event => setFieldValue(name, event.target.value)}
      {...props}
    />
  );
}

function Colorimetria() {
  const [data, setData] = useState([]);
  //Controladores de estados del modal
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState('');
  //

  useEffect(() => {
    fetch(urls.configColorimetria, {
      
      method: 'GET',
      headers: {
        accesstoken: sessionStorage.getItem('JWT')
      },
    })
    
    .then((response) => response.json())
    .then(data => {
      data.sort((a, b) => a.id_colorimetria - b.id_colorimetria);
      //console.log(data);
      setData(data);
    })
    .catch(err => {
      setMessage("Error de comunicación: " + err);
      setShowModal(true);
    })
  }, []);

  return (
    <>
    <div className="form-container">
      {data.map(item => (
        <React.Fragment key={item.id_colorimetria}>
        <div className="form-wrapper" >
          <Formik
            initialValues={item}
            onSubmit={values => {
              const updatedValues = {
                ...values,
                codigo_hex: values.codigo_hex,
                color:  values.color,
                estado: values.estado,
              };
            
              fetch(urls.configColorimetriaId(item.id_colorimetria), {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                  accesstoken: sessionStorage.getItem('JWT')
                },
                body: JSON.stringify(updatedValues)
              })
              .then(response => response.json())
              .then(response => {
                setMessage(response.message);
                setShowModal(true);
              })
              .catch(err => {
                setMessage("Error de comunicación: " + err);
                setShowModal(true);
              });
            }}
          >
            <Form>
              <FormikTextField name="codigo_hex" label="codigo_hex" type="text" />
              <br />
              <FormikTextField name="color" label="color" type="text" />
              <br />
              <FormikTextField name="estado" label="estado" type="text" />
              <br />
              <button type="submit">Guardar</button>
            </Form>
          </Formik>

          {showModal && <Modal 
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        message={message} onClose={() => setShowModal(false)} />}
        
          </div>
          </React.Fragment>
      ))}
      </div>
    </>
  );
}

export default Colorimetria;
