import React, { useState, useEffect } from 'react';
import { Formik, Form, useFormikContext } from 'formik';
import TextField from '@mui/material/TextField';


import Header from "../../components/Header";
//Componentes para el texto del modal
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
//

import { urls } from "../../data/urls";

function Modal({ message, onClose }) {
  return (
    
    <Dialog open={true} onClose={onClose}>
      <DialogContent>
        <p>{message}</p>
      </DialogContent>
    </Dialog>
  );
} 


function FormikTextField({ name, ...props }) {
  const { values, setFieldValue } = useFormikContext();
  return (
    <TextField
      name={name}
      value={values[name]}
      onChange={event => setFieldValue(name, event.target.value)}
      {...props}
    />
  );
}

function Sensor() {
  const [data, setData] = useState([]);
  //Controladores de estados del modal
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState('');
  //

  useEffect(() => {
    fetch(urls.configTipoSensor, {
      
      method: 'GET',
      headers: {
        accesstoken: sessionStorage.getItem('JWT')
      },
    })
    
    .then((response) => response.json())
    .then(data => {
      data.sort((a, b) => a.id_tipo_sensor - b.id_tipo_sensor);
      //console.log(data);
      setData(data);
    })
    .catch(err => {
      setMessage("Error de comunicación: " + err);
      setShowModal(true);
    })
  }, []);

  return (
    <>
    <div className="form-container">
      {data.map(item => (
        <React.Fragment key={item.id_tipo_sensor}>
        <div className="form-wrapper"  >
          
          <Formik
          
           
            initialValues={item}
            onSubmit={values => {
              const updatedValues = {
                ...values,
                descripcion: values.descripcion,
                elemento: values.elemento,
                valor_tipo_sensor: Number(values.valor_tipo_sensor),
              };
            
              fetch(urls.configTipoSensorId(item.id_tipo_sensor), {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                  accesstoken: sessionStorage.getItem('JWT')
                },
                body: JSON.stringify(updatedValues)
              })
              .then(response => response.json())
              .then(response => {
                setMessage(response.message);
                setShowModal(true);
              })
              .catch(err => {
                setMessage("Error de comunicación: " + err);
                setShowModal(true);
              });
            }}
          >
            <Form>
              <FormikTextField name="descripcion" label="descripcion" type="text" />
              <br />
              <FormikTextField name="elemento" label="elemento" type="text" />
              <br />
              <FormikTextField name="valor_tipo_sensor" label="valor" type="number" />
              <br />
              <button type="submit">Guardar</button>
            </Form>
          </Formik>
          {showModal && <Modal 
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        message={message} onClose={() => setShowModal(false)} />}
          </div>
          </React.Fragment>
      ))}
      </div>
    </>
  );
}


export default Sensor;
