import { Box, Button} from "@mui/material";

import Header from "../../components/Header";

import 'leaflet/dist/leaflet.css';

import CloudOutlinedIcon from '@mui/icons-material/CloudOutlined';
import Co2OutlinedIcon from '@mui/icons-material/Co2Outlined';

import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';

const MyButton = styled(Button)({
  backgroundColor:  '#1F2A40',
  color: '#e0e0e0',
  fontSize: '14px',
  fontWeight: 'bold',
  gridColumn: 'span 2',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  marginTop: '25px',
  padding: '0 30px',
  '@media (min-width:768px)': {
    fontSize: '16px',
    padding: '10px 40px',
    margin: '10px',
  },
});

const ButtonContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
  '@media (max-width:768px)': {
    flexDirection: 'column',
    alignItems: 'center',
  },
});

const MyIcon1 = styled(Co2OutlinedIcon)({
  color: '#70d8bd',
  fontSize: '26px',
});

const MyIcon2 = styled(CloudOutlinedIcon)({
  color: '#70d8bd',
  fontSize: '26px',
});

const AlarmasMenu = () => {
  const navigate = useNavigate();

  

  

  return (
    <>
    <Box m="20px" display="flex" justifyContent="space-between" alignItems="center">
        <Header title="CRUD DE USUARIOS" subtitle="Crea, edita, actualiza y borra usuarios." />
    </Box>
    <ButtonContainer>
      <MyButton
        onClick={() => {
          navigate('/usuarios/consultar');
        }}
      >
        <MyIcon1 />
        Consulta de Usuarios
      </MyButton>
      <MyButton
        onClick={() => {
          navigate('/usuarios/crear');
        }}
      >
        <MyIcon2 />
        Creación de Usuarios
      </MyButton>



      {/* ... agregar más botones según sea necesario */}
    </ButtonContainer>
    </>
  );
};

export default AlarmasMenu;
