import React, { useState, useEffect } from 'react';
import { Formik, Form, useFormikContext } from 'formik';
import TextField from '@mui/material/TextField';


import Header from "../../components/Header";

//Componentes para el texto del modal
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
//

import { urls } from "../../data/urls";

function Modal({ message, onClose }) {
  return (
    
    <Dialog open={true} onClose={onClose}>
      <DialogContent>
        <p>{message}</p>
      </DialogContent>
    </Dialog>
  );
} 
function FormikTextField({ name, ...props }) {
  const { values, setFieldValue } = useFormikContext();
  return (
    <TextField
      name={name}
      value={values[name]}
      onChange={event => setFieldValue(name, event.target.value)}
      {...props}
    />
  );
}

function Meteorologico() {
  const [data, setData] = useState([]);
  //Controladores de estados del modal
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState('');
  //

  useEffect(() => {
    fetch(urls.configMeteorologico, {
      
      method: 'GET',
      headers: {
        accesstoken: sessionStorage.getItem('JWT')
      },
    })
    
    .then((response) => response.json())
    .then(data => {
      data.sort((a, b) => a.id_meteorologico - b.id_meteorologico);
      //console.log(data);
      setData(data);
    })
    .catch(err => {
      setMessage("Error de comunicación: " + err);
      setShowModal(true);
    })
  }, []);

  return (
    <>
    <div className="form-container">
      {data.map(item => (
        <React.Fragment key={item.id_meteorologico}>
        <div className="form-wrapper" >
          <Header title={item.nombre} />
          <Formik
          
            
            initialValues={item}
            onSubmit={values => {
              const updatedValues = {
                ...values,
                
                color_limite_inferior: Number(values.color_limite_inferior),
                color_limite_superior: Number(values.color_limite_superior),
                id_tipo_sensor: Number(values.id_tipo_sensor),
                id_unidad_medida: Number(values.id_unidad_medida),
                limite_inferior: Number(values.limite_inferior),
                limite_superior: Number(values.limite_superior),
                mensaje_limite_inferior: values.mensaje_limite_inferior,
                mensaje_limite_superior: values.limite_inferior,
                nombre: values.nombre,
                sonido_limite_inferior: values.sonido_limite_inferior,
                sonido_limite_superior: values.sonido_limite_superior,
              };
            
              fetch(urls.configMeteorologicoId(item.id_meteorologico), {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                  accesstoken: sessionStorage.getItem('JWT')
                },
                body: JSON.stringify(updatedValues)
              })
              .then(response => response.json())
              .then(response => {
                setMessage(response.message);
                setShowModal(true);
              })
              .catch(err => {
                setMessage("Error de comunicación: " + err);
                setShowModal(true);
              });
            }}
          >
            <Form>
              <FormikTextField name="nombre" label="nombre" />
              <br />
              <FormikTextField name="color_limite_inferior" label="color_limite_inferior" type="number" />
              <br />
              <FormikTextField name="color_limite_superior" label="color_limite_superior" type="number" />
              <br />
              <FormikTextField name="id_tipo_sensor" label="id_tipo_sensor" type="number" />
              <br />
              <FormikTextField name="limite_inferior" label="limite_inferior" type="number" />
              <br />
              <FormikTextField name="limite_superior" label="limite_superior" type="number" />
              <br />
              <FormikTextField name="mensaje_limite_inferior" label="mensaje_limite_inferior" type="text" />
              <br />
              <FormikTextField name="mensaje_limite_superior" label="mensaje_limite_superior" type="text" />
              <br />
              <FormikTextField name="nombre" label="nombre" type="text" />
              <br />
              <FormikTextField name="sonido_limite_inferior" label="sonido_limite_inferior" type="text" />
              <br />
              <FormikTextField name="sonido_limite_superior" label="sonido_limite_superior" type="text" />
              <br />
              <button type="submit">Submit</button>
            </Form>
          </Formik>
          {showModal && <Modal 
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        message={message} onClose={() => setShowModal(false)} />}
          </div>
          </React.Fragment>
      ))}
      </div>
    </>
  );
}



export default Meteorologico;
