import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";

import * as React from 'react';
import {useState } from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

//Componentes para el texto del modal
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
//
import { urls } from "../../data/urls";

function Modal({ message, onClose }) {
  return (
    
    <Dialog open={true} onClose={onClose}>
      <DialogContent>
        <p>{message}</p>
      </DialogContent>
    </Dialog>
  );
}

const CrearTipo = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  //Controladores de estados del modal
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState('');
  //



  const handleFormSubmit = async (values) => {
    // sessionStorage.setItem('nombre', values.userName); //cuando no sean pruebas hay que quitarlo
    
    
    fetch(urls.estaciones, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        accesstoken: sessionStorage.getItem('JWT')
      },
      body: JSON.stringify({
        nombre: values.nombre,
        alias: values.alias,
        email: values.email,
        longitud: values.longitud,
        latitud: values.latitud,
        id_tipo_equipo: values.id_tipo_equipo,
        id_estado_operacion: values.id_estado_operacion,
        id_modo_operacion: values.id_modo_operacion,
        
      })
    })
    .then((response) => response.json())
    .then(response => {
      console.log(response);
      setMessage(JSON.stringify(response.message));
      setShowModal(true);
    })
    .catch(err => {
      console.log("fetch error" + err);
      setMessage("Error de comunicación: " + err);
      setShowModal(true);
    })
    
   setMessage("El usuario: "+ JSON.stringify(values.userName) + " se ha creado correctamente.");
   setShowModal(true);
   
   console.log(values);
  };

  return (
    <Box m="20px">
      <Header title="CREAR ESTACIONES" subtitle="Crear una nueva estación" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Nombre"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.nombre}
                name="nombre"
                error={!!touched.nombre && !!errors.nombre}
                helperText={touched.nombre && errors.nombre}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64 }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Alias"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.alias}
                name="alias"
                error={!!touched.alias && !!errors.alias}
                helperText={touched.alias && errors.alias}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64 }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64 }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Latitud"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.latitud}
                name="latitud"
                error={!!touched.latitud && !!errors.latitud}
                helperText={touched.latitud && errors.latitud}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64 }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Longitud"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.longitud}
                name="longitud"
                error={!!touched.longitud && !!errors.longitud}
                helperText={touched.longitud && errors.longitud}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64 }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="id_estado_operacion"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.id_estado_operacion}
                name="id_estado_operacion"
                error={!!touched.id_estado_operacion && !!errors.id_estado_operacion}
                helperText={touched.id_estado_operacion && errors.id_estado_operacion}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64 }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="id_modo_operacion"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.id_modo_operacion}
                name="id_modo_operacion"
                error={!!touched.id_modo_operacion && !!errors.id_modo_operacion}
                helperText={touched.id_modo_operacion && errors.id_modo_operacion}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64 }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="id_tipo_equipo"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.id_tipo_equipo}
                name="id_tipo_equipo"
                error={!!touched.id_tipo_equipo && !!errors.id_tipo_equipo}
                helperText={touched.id_tipo_equipo && errors.id_tipo_equipo}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64 }}
              />



            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Crear Nuevo Usuario
              </Button>
            </Box>
          </form>
        )}
      </Formik>

      {showModal && <Modal 
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        message={message} onClose={() => setShowModal(false)} />}



    </Box>
  );
};



const checkoutSchema = yup.object().shape({
  nombre: yup.string().required("required"),
  alias: yup.string().required("required"),
  email_alarmas: yup.string().email("invalid email").required("required"),
  latitud: yup.number().required("required"),
  longitud: yup.number().required("required"),
  id_estado_operacion: yup.number().integer().positive().required("required"),
  id_modo_operacion: yup.number().integer().positive().required("required"),
  id_tipo_equipo: yup.number().integer().positive().required("required"),
});

const initialValues = {
  nombre: "",
  alias:  "",
  email: "",
  longitud: "",
  latitud: "",
  id_tipo_equipo: "",
  id_estado_operacion: "",
  id_modo_operacion: "",
};

export default CrearTipo;
