import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";

import * as React from 'react';
import {useState } from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

//Componentes para el texto del modal
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
//

//Componentes para la visibilidad on/off de la contraseña
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import {sha256} from 'crypto-hash';
//

import { urls } from "../../data/urls";

function Modal({ message, onClose }) {
  return (
    
    <Dialog open={true} onClose={onClose}>
      <DialogContent>
        <p>{message}</p>
      </DialogContent>
    </Dialog>
  );
}

const FormGas= () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  //Controladores de estados del modal
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState('');
  //

  //Controladores de estados de la contraseña
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  //


  const handleFormSubmit = async (values) => {
    // sessionStorage.setItem('nombre', values.userName); //cuando no sean pruebas hay que quitarlo
    
    
    
    fetch(urls.configGas, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        accesstoken: sessionStorage.getItem('JWT')
      },
      body: JSON.stringify({
        
        caracteristicas: values.caracteristicas,
        color_alarma_alta: values.color_alarma_alta,
        color_alarma_media: values.color_alarma_media,
        default_alarma_alta_inferior: values.default_alarma_alta_inferior,
        default_alarma_alta_superior: values.default_alarma_alta_superior,
        default_alarma_media_inferior: values.default_alarma_media_inferior,
        default_alarma_media_superior: values.default_alarma_media_superior,
        id_gas: values.id_gas,
        id_tipo_sensor: values.id_tipo_sensor,
        id_unidad_medida: values.id_unidad_medida,
        mensaje_alarma_alta: values.mensaje_alarma_alta,
        mensaje_alarma_media: values.mensaje_alarma_media,
        nombre: values.nombre,
        sonido_alarma_alta: values.sonido_alarma_alta,
        sonido_alarma_media: values.sonido_alarma_media,
        
      })
    })
    .then((response) => response.json())
    .then(response => {
      //console.log(response);
      setMessage(JSON.stringify(response.message));
      setShowModal(true);
    })
    .catch(err => {
      //console.log("fetch error" + err);
      setMessage("Error de comunicación: " + err);
      setShowModal(true);
    })
    
   setMessage("El usuario: "+ JSON.stringify(values.userName) + " se ha creado correctamente.");
   setShowModal(true);
   
   //console.log(values);
  };

  return (
    <Box m="20px">
      <Header title="CREAR GAS" subtitle="Crear un nuevo registro en Gas" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="caracteristicas"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.caracteristicas}
                name="caracteristicas"
                error={!!touched.caracteristicas && !!errors.caracteristicas}
                helperText={touched.caracteristicas && errors.caracteristicas}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64 }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="color_alarma_alta"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.color_alarma_alta}
                name="color_alarma_alta"
                error={!!touched.color_alarma_alta && !!errors.color_alarma_alta}
                helperText={touched.color_alarma_alta && errors.color_alarma_alta}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64}}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="color_alarma_media"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.color_alarma_media}
                name="color_alarma_media"
                error={!!touched.color_alarma_media && !!errors.color_alarma_media}
                helperText={touched.color_alarma_media && errors.color_alarma_media}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64 }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="default_alarma_alta_inferior"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.default_alarma_alta_inferior}
                name="default_alarma_alta_inferior"
                error={!!touched.default_alarma_alta_inferior && !!errors.default_alarma_alta_inferior}
                helperText={touched.default_alarma_alta_inferior && errors.default_alarma_alta_inferior}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64 }}
              />
            <TextField
                fullWidth
                variant="filled"
                type="number"
                label="default_alarma_alta_superior"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.default_alarma_alta_superior}
                name="default_alarma_alta_superior"
                error={!!touched.default_alarma_alta_superior && !!errors.default_alarma_alta_superior}
                helperText={touched.default_alarma_alta_superior && errors.default_alarma_alta_superior}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64 }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="default_alarma_media_inferior"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.default_alarma_media_inferior}
                name="default_alarma_media_inferior"
                error={!!touched.default_alarma_media_inferior && !!errors.default_alarma_media_inferior}
                helperText={touched.default_alarma_media_inferior && errors.default_alarma_media_inferior}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64 }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="default_alarma_media_superior"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.default_alarma_media_superior}
                name="default_alarma_media_superior"
                error={!!touched.default_alarma_media_superior && !!errors.default_alarma_media_superior}
                helperText={touched.default_alarma_media_superior && errors.default_alarma_media_superior}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64 }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="id_gas"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.id_gas}
                name="id_gas"
                error={!!touched.id_gas && !!errors.id_gas}
                helperText={touched.id_gas && errors.id_gas}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64 }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="id_tipo_sensor"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.id_tipo_sensor}
                name="id_tipo_sensor"
                error={!!touched.id_tipo_sensor && !!errors.id_tipo_sensor}
                helperText={touched.id_tipo_sensor && errors.id_tipo_sensor}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64 }}
              />
            <TextField
                fullWidth
                variant="filled"
                type="number"
                label="id_unidad_medida"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.id_unidad_medida}
                name="id_unidad_medida"
                error={!!touched.id_unidad_medida && !!errors.id_unidad_medida}
                helperText={touched.id_unidad_medida && errors.id_unidad_medida}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64 }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="mensaje_alarma_alta"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.mensaje_alarma_alta}
                name="id_tipo_sensor"
                error={!!touched.mensaje_alarma_alta && !!errors.mensaje_alarma_alta}
                helperText={touched.mensaje_alarma_alta && errors.mensaje_alarma_alta}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64 }}
              />
            <TextField
                fullWidth
                variant="filled"
                type="text"
                label="mensaje_alarma_media"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.mensaje_alarma_media}
                name="mensaje_alarma_media"
                error={!!touched.mensaje_alarma_media && !!errors.mensaje_alarma_media}
                helperText={touched.mensaje_alarma_media && errors.mensaje_alarma_media}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64 }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="nombre"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.nombre}
                name="nombre"
                error={!!touched.nombre && !!errors.nombre}
                helperText={touched.nombre && errors.nombre}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64 }}
              />
            <TextField
                fullWidth
                variant="filled"
                type="text"
                label="sonido_alarma_alta"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.sonido_alarma_alta}
                name="sonido_limite_inferior"
                error={!!touched.sonido_alarma_alta && !!errors.sonido_alarma_alta}
                helperText={touched.sonido_alarma_alta && errors.sonido_alarma_alta}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64 }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="sonido_alarma_media"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.sonido_alarma_media}
                name="sonido_alarma_media"
                error={!!touched.sonido_alarma_media && !!errors.sonido_alarma_media}
                helperText={touched.sonido_alarma_media && errors.sonido_alarma_media}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64 }}
              />




            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Crear Nuevo
              </Button>
            </Box>
          </form>
        )}
      </Formik>

      {showModal && <Modal 
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        message={message} onClose={() => setShowModal(false)} />}



    </Box>
  );
};



const checkoutSchema = yup.object().shape({
  caracteristicas: yup.string().required("required"),
  color_alarma_alta:yup.number().required("required"),
  color_alarma_media: yup.number().required("required"),
  default_alarma_alta_inferior: yup.number().required("required"),
  default_alarma_alta_superior: yup.number().required("required"),
  default_alarma_media_inferior: yup.number().required("required"),
  default_alarma_media_superior: yup.number().required("required"),
  id_gas: yup.number().required("required"),
  id_tipo_sensor: yup.number().required("required"),
  id_unidad_medida: yup.number().required("required"),
  mensaje_alarma_alta: yup.string().required("required"),
  mensaje_alarma_media: yup.string().required("required"),
  nombre: yup.string().required("required"),
  sonido_alarma_alta: yup.string().required("required"),
  sonido_alarma_media: yup.string().required("required"),
});
const initialValues = {
  caracteristicas: "",
  color_alarma_alta: "",
  color_alarma_media: "",
  default_alarma_alta_inferior: "",
  default_alarma_alta_superior: "",
  default_alarma_media_inferior: "",
  default_alarma_media_superior: "",
  id_gas: "",
  id_tipo_sensor: "",
  id_unidad_medida: "",
  mensaje_alarma_alta: "",
  mensaje_alarma_media: "",
  nombre: "",
  sonido_alarma_alta: "",
  sonido_alarma_media: "",
};

export default FormGas;
