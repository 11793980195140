import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';

const Item = ({ title, to, icon, selected, setSelected }) => {
  // Accede al tema actual
  const theme = useTheme();
  // Obtiene los colores del tema
  const colors = tokens(theme.palette.mode);
  // Devuelve un elemento JSX
  return (
    <MenuItem
      // Establece la prop active en verdadero si selected es igual a title
      active={selected === title}
      // Establece el color del texto en gris claro
      style={{
        color: colors.grey[100],
      }}
      // Llama a setSelected con el valor de title cuando se hace clic en el elemento
      onClick={() => setSelected(title)}
      // Establece la prop icon con el valor de la prop icon
      icon={icon}
    >
      {/* Muestra el valor de la prop title */}
      <Typography>{title}</Typography>
      {/* Establece la prop to con el valor de la prop to */}
      <Link to={to} />
    </MenuItem>
  );
};


const Sidebar = () => {
  // Accede al tema actual
  const theme = useTheme();
  // Obtiene los colores del tema
  const colors = tokens(theme.palette.mode);
  // Define los estados isCollapsed y selected
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Inicio");
  // Obtiene el valor de la clave 'permisos' del almacenamiento de sesión y lo convierte en un objeto JSON
  const permisos = JSON.parse(sessionStorage.getItem('permisos'));
  // Obtiene el valor de la clave 'rol' del almacenamiento de sesión
  const rol = sessionStorage.getItem('rol');
  let rolname;
  // Asigna un valor a rolname en función del valor de rol
  if (rol == 1) {
    rolname = 'Superadmin';
  } 
  else if (rol == 2) {
    rolname = 'Administrador';
  } 
  else if (rol == 3) {
    rolname = 'Operador A';
  } 
  else if (rol == 4) {
    rolname = 'Operador B';
  } 
  else {
    rolname = 'Visor';
  }


  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <div className='sidebar'>
      <ProSidebar collapsed={isCollapsed} style={{ height: "100%" }} defaultCollapsed={true}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  SIAPA
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={`../../assets/user.png`}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  {sessionStorage.getItem('nombre')}
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                {rolname}
                </Typography>
              </Box>
            </Box>
          )}
          <Box paddingLeft={isCollapsed ? undefined : "10%"}>

            <Typography
              // Establece el estilo del texto en "h6"
              variant="h6"
              // Establece el color del texto en gris claro
              color={colors.grey[300]}
              // Establece los márgenes del elemento
              sx={{ m: "15px 0 5px 20px" }}
            >
              Datos
            </Typography>

            <Item
              // Establece la prop title con el valor "Estaciones"
              title="Estaciones"
              // Establece la prop to con el valor "/inicio"
              to="/inicio"
              // Establece la prop icon con un elemento HomeOutlinedIcon
              icon={<HomeOutlinedIcon />}
              // Establece las props selected y setSelected
              selected={selected}
              setSelected={setSelected}
            />


            {!(permisos.length === 1 && permisos.includes(6)) && (
              <Item
                title="Alarmas"
                to="/alarmas"
                icon={<AccessAlarmOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}

            {(permisos.includes(1) || permisos.includes(2)) && (
            <Item
              title="Ajustes de variables"
              to="/ajustes_variables"
              icon={<SettingsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            )}

            {(permisos.includes(1) || permisos.includes(2)) && (
              <>
              
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  Usuarios
                </Typography>
                <Item
                  title="CRUD de Usuarios"
                  to="/usuarios"
                  icon={<ContactsOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </>
              )}

            {permisos.includes(1) && (
              <>
              {/*
              <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  Sistema
                </Typography>
            <Item
              title="Ajustes del sistema"
              to="/sistema"
              icon={<SettingsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            */}
            <Item
              title="CRUD de Estaciones"
              to="/ajustes_estacion"
              icon={<LibraryAddOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {/*
            <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  Registros
            </Typography>
            <Item
              title="Logs"
              to="/logs"
              icon={<LibraryBooksOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            */}
            </>
            )}
            


          </Box>
        </Menu>
      </ProSidebar>
      </div>
    </Box>
  );
};

export default Sidebar;
