import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";

import * as React from 'react';
import {useState } from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

//Componentes para el texto del modal
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
//

//Componentes para la visibilidad on/off de la contraseña
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import {sha256} from 'crypto-hash';
//

import { urls } from "../../data/urls";

function Modal({ message, onClose }) {
  return (
    
    <Dialog open={true} onClose={onClose}>
      <DialogContent>
        <p>{message}</p>
      </DialogContent>
    </Dialog>
  );
}

const FormMeteorologico= () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  //Controladores de estados del modal
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState('');
  //

  //Controladores de estados de la contraseña
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  //


  const handleFormSubmit = async (values) => {
    // sessionStorage.setItem('nombre', values.userName); //cuando no sean pruebas hay que quitarlo
    
    
    
    fetch(urls.configMeteorologico, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        accesstoken: sessionStorage.getItem('JWT')
      },
      body: JSON.stringify({
        color_limite_inferior: values.color_limite_inferior,
        color_limite_superior: values.color_limite_superior,
        id_tipo_sensor: values.id_tipo_sensor,
        id_unidad_medida: values.id_unidad_medida,
        limite_inferior: values.limite_inferior,
        limite_superior: values.limite_superior,
        mensaje_limite_inferior: values.mensaje_limite_inferior,
        mensaje_limite_superior: values.mensaje_limite_superior,
        nombre: values.nombre,
        sonido_limite_inferior: values.sonido_limite_inferior,
        sonido_limite_superior: values.sonido_limite_superior,
        
      })
    })
    .then((response) => response.json())
    .then(response => {
      //console.log(response);
      setMessage(JSON.stringify(response.message));
      setShowModal(true);
    })
    .catch(err => {
      //console.log("fetch error" + err);
      setMessage("Error de comunicación: " + err);
      setShowModal(true);
    })
    
   setMessage("El registro: "+ JSON.stringify(values.nombre) + " se ha creado correctamente.");
   setShowModal(true);
   
   //console.log(values);
  };

  return (
    <Box m="20px">
      <Header title="CREAR METEOROLOGICO" subtitle="Crear un nuevo registro en Meteorologico" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="color_limite_inferior"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.color_limite_inferior}
                name="color_limite_inferior"
                error={!!touched.color_limite_inferior && !!errors.color_limite_inferior}
                helperText={touched.color_limite_inferior && errors.color_limite_inferior}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64}}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="color_limite_superior"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.color_limite_superior}
                name="color_limite_superior"
                error={!!touched.color_limite_superior && !!errors.color_limite_superior}
                helperText={touched.color_limite_superior && errors.color_limite_superior}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64 }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="id_tipo_sensor"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.id_tipo_sensor}
                name="id_tipo_sensor"
                error={!!touched.id_tipo_sensor && !!errors.id_tipo_sensor}
                helperText={touched.id_tipo_sensor && errors.id_tipo_sensor}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64 }}
              />
            <TextField
                fullWidth
                variant="filled"
                type="number"
                label="id_unidad_medida"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.id_unidad_medida}
                name="id_unidad_medida"
                error={!!touched.id_unidad_medida && !!errors.id_unidad_medida}
                helperText={touched.id_unidad_medida && errors.id_unidad_medida}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64 }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="limite_inferior"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.limite_inferior}
                name="limite_inferior"
                error={!!touched.limite_inferior && !!errors.limite_inferior}
                helperText={touched.limite_inferior && errors.limite_inferior}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64 }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="limite_superior"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.limite_superior}
                name="limite_superior"
                error={!!touched.limite_superior && !!errors.limite_superior}
                helperText={touched.limite_superior && errors.limite_superior}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64 }}
              />
<TextField
                fullWidth
                variant="filled"
                type="text"
                label="mensaje_limite_inferior"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.mensaje_limite_inferior}
                name="mensaje_limite_inferior"
                error={!!touched.mensaje_limite_inferior && !!errors.mensaje_limite_inferior}
                helperText={touched.mensaje_limite_inferior && errors.mensaje_limite_inferior}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64 }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="mensaje_limite_superior"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.mensaje_limite_superior}
                name="mensaje_limite_superior"
                error={!!touched.mensaje_limite_superior && !!errors.mensaje_limite_superior}
                helperText={touched.mensaje_limite_superior && errors.mensaje_limite_superior}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64 }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="nombre"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.nombre}
                name="nombre"
                error={!!touched.nombre && !!errors.nombre}
                helperText={touched.nombre && errors.nombre}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64 }}
              />
            <TextField
                fullWidth
                variant="filled"
                type="text"
                label="sonido_limite_inferior"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.sonido_limite_inferior}
                name="sonido_limite_inferior"
                error={!!touched.sonido_limite_inferior && !!errors.sonido_limite_inferior}
                helperText={touched.sonido_limite_inferior && errors.sonido_limite_inferior}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64 }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="sonido_limite_superior"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.sonido_limite_superior}
                name="sonido_limite_superior"
                error={!!touched.sonido_limite_superior && !!errors.sonido_limite_superior}
                helperText={touched.sonido_limite_superior && errors.sonido_limite_superior}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 64 }}
              />




            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Crear Nuevo
              </Button>
            </Box>
          </form>
        )}
      </Formik>

      {showModal && <Modal 
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        message={message} onClose={() => setShowModal(false)} />}



    </Box>
  );
};



const checkoutSchema = yup.object().shape({
  color_limite_inferior: yup.number().required("required"),
  color_limite_superior: yup.number().required("required"),
  id_tipo_sensor: yup.number().required("required"),
  id_unidad_medida: yup.number().required("required"),
  mensaje_limite_inferior: yup.string().required("required"),
  mensaje_limite_superior: yup.string().required("required"),
  limite_inferior: yup.number().required("required"),
  limite_superior: yup.number().required("required"),
  nombre: yup.string().required("required"),
  sonido_limite_inferior: yup.string().required("required"),
  sonido_limite_superior: yup.string().required("required"),
});
const initialValues = {
  color_limite_inferior: "",
  color_limite_superior: "",
  id_tipo_sensor: "",
  id_unidad_medida: "",
  limite_inferior: "",
  limite_superior: "",
  mensaje_limite_inferior: "",
  mensaje_limite_superior: "",
  nombre: "",
  sonido_limite_inferior: "",
  sonido_limite_superior: "",
};

export default FormMeteorologico;
